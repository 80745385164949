import magnifyingGlass from '../../../theme/assets/animation_Loader.gif';
import './LoadingOverlay.scss';

const LoadingOverlay = (props) => (
  <div className={'loading-overlay-container'}>
    <img
      className={'loading-overlay-spinner'}
      src={magnifyingGlass}
      alt={'loading'}
      id={'loading-spinner'}
      />
    <div className={'flex-container flex-row flex-justify-center loading-message'}>
      {
        (props && props.loadingMsg) && <p>{props.loadingMsg}</p>
      }
    </div>
  </div>
);

export default LoadingOverlay;