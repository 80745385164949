import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../shared/components/Button';
import Checkbox from '../shared/components/CheckBox';
import StyledModal from '../shared/components/StyledModal';
import { postConsent } from '../../redux/actions/consent';
import LoadingOverlay from '../shared/components/LoadingOverlay';
import '../theme/styles/global.scss';
import './Consent.scss';

const Consent = () => {
  const dispatch = useDispatch();
  const [isCancelDisabled, setIsCancelDisabled] = useState(true);
  const [isShareMyRecordDisabled, setIsShareMyRecordDisabled] = useState(false);
  const [isConsentBoxChecked, setIsConsentBoxChecked] = useState(true);
  const [partnerName, setPartnerName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const response_redirect_url = useSelector(
    (state) => state.consent.redirect_url
  );

  useEffect(() => {
    setPartnerName(user && user.client && user.client.app_name || 'App');
  }, []);

  useEffect(() => {
    if (response_redirect_url) {
      window.location = response_redirect_url;
    }
  }, [response_redirect_url]);

  const handleConsentBoxToggle = () => {
    setIsConsentBoxChecked(!isConsentBoxChecked);
    setIsCancelDisabled(!isCancelDisabled);
    setIsShareMyRecordDisabled(!isShareMyRecordDisabled);
  };

  const handleShareMyRecord = () => {
    setIsShareMyRecordDisabled(true);
    setIsLoading(true);

    dispatch(
      postConsent({
        consent: true,
        client_id: user.client.client_id,
        redirect_uri: user.client.redirect_uri,
        user_info: user.user.auth.attributes.sub,
        scope: user.scope,
        state: user.client.state ? user.client.state : ''
      })
    );
  };

  const handleDontShareRecord = () => {
    dispatch(
      postConsent({
        consent: false,
        client_id: user.client.client_id,
        redirect_uri: user.client.redirect_uri,
        user_info: user.user.auth.attributes.sub,
        scope: user.scope,
        state: user.client.state ? user.client.state : ''
      })
    );
  };

  const handleCancel = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={'screen-container style-overrides'}>
      <div className={'consent-screen'}>
        <div>
          <h1 className={'layout-padding-xlarge-top'}>
            We found your COVID-19 vaccination information!
          </h1>
        </div>

        <div className={'authorization-box'}>
          <div>
            <input type={'checkbox'}></input>
            <Checkbox
              id={'consent-checkbox'}
              value={'consent-box'}
              checked={isConsentBoxChecked}
              onChange={handleConsentBoxToggle}
            />
          </div>
          <div>
            <p className={'authorization-text'}>
              By checking this box, I authorize disclosure of my protected
              health information to {partnerName}
            </p>
          </div>
        </div>

        <div className={'flex-direction-column'}>
          <div>
            <Button
              id={'shareRecord'}
              className={'Button Button--primary default__button-label default__button-label--primary'}
              disabled={isShareMyRecordDisabled}
              onClick={handleShareMyRecord}
            >
              Share my record
            </Button>
          </div>
          <div className={'layout-padding-medium-top'}>
            <Button
              id={'cancelShareRecord'}
              className={'Button default__button-label no-border-button'}
              disabled={isCancelDisabled}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>

        <StyledModal
          title={'Share record'}
          leaveText={`Are you sure you don't want to share your COVID-19 vaccine information with ${partnerName}?`}
          show={isModalOpen}
          showCancel={true}
          confirmButtonLabel={'Don\'t share my record'}
          onClose={handleModalClose}
          onConfirm={handleDontShareRecord}
        />

        {isLoading && <LoadingOverlay loadingMsg={'Sharing your consent...'} />}

      </div>
    </div>
  );
};

export default Consent;
