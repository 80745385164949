import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setContainerHeight } from '../../shared/utilities/effects';
import { clientRedirectUriErrorParams } from '../../shared/utilities/errorMessages';
import Button from '../../shared/components/Button/index';
import '../../theme/styles/global.scss';
import './FindRecords.scss';

const RecordNotFound = () => {
  const targetRef = useRef();
  const [screenHeight, setScreenHeight] = useState(0);
  const client = useSelector((state) => state.user.client);

  useLayoutEffect(() => {
    if (targetRef.current) {
      let options = {
        manualHeightOffset: 70,
        useHeader: true,
        useFooter: true,
      };
      setScreenHeight(setContainerHeight(options));
    }
  }, []);

  const routeToExternalApp = () => {
    const stateParameter = client.state ? '&state=' + client.state : '';
    const externalAppUrl = `${client.redirect_uri}${clientRedirectUriErrorParams}${stateParameter}`;
    window.location.href = externalAppUrl;
  };

  return (
    <div
      className={'screen-container records__flex-container style-overrides'}
      ref={targetRef}
      style={{ height: screenHeight + 'px' }}
    >
      <div>
        <h1 className={'layout-padding-xlarge-top'}>
          We were unable to find your vaccine information at this time.
        </h1>
        <h4 className={'layout-padding-large-top'}>
          Please check back in 24-48 hours as your COVID record may be
          available then.
        </h4>
      </div>

      <div>
        <Button
          id={'btnReturnToApp'}
          className={'Button Button--primary default__button-label default__button-label--primary'}
          onClick={() => routeToExternalApp()}>
          Return to {
              client ? client.app_name : 'App'
            }
        </Button>
      </div>
    </div>
  );
};

export default withRouter(RecordNotFound);
