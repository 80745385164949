import { SET_BOTCO_REFERRAL_CODE } from '../constants';
import { BOTCO_REFERRAL_CODES } from '../../modules/botco/constants';

export const botcoReducer = (
  state = {
    params: {
      apiKey: process.env.REACT_APP_BOTCO_API_KEY,
      referralCode: BOTCO_REFERRAL_CODES.default,
    },
  },
  action
) => {
  switch (action.type) {
    case SET_BOTCO_REFERRAL_CODE:
      return {
        ...state,
        params: { ...state.params, referralCode: action.payload },
      };
    default:
      return state;
  }
};
