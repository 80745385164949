import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../shared/components/Button';
import TextInput from '../../shared/components/TextInput';
import { validateData } from '../../shared/utilities/validation';
import {
  getPWResetVerificationCode,
  resetPassword,
  resetPasswordResetStep,
  clearCognitoError
} from '../../../redux/actions/user';
import { SET_EMAIL } from '../../../redux/constants';
import PasswordStrength from "./PasswordStrength";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [destination, setDestination] = useState('');
  const { error: cognitoError } = useSelector((state) => state.user);
  const { demographics, email, passwordResetStep: step, deliveryMedium } = useSelector((state) => state.user.user);

  useEffect(() => {
    if (demographics && demographics.email && !email) {
      setEmailAddress(demographics.email);

      // Validate if the email address received is valid
      let emailError = validateData('emailAddress', 'email', demographics.email);
      setErrors((preErrors) => ({ ...preErrors, ...emailError }));
    } else if (email) {
      setEmailAddress(email);

      let emailError = validateData('emailAddress', 'email', email);
      setErrors((preErrors) => ({ ...preErrors, ...emailError }));
    }
  }, []);

  useEffect(() => {
    if (deliveryMedium) {
      setDestination(deliveryMedium.Destination);
    }
  }, [deliveryMedium]);

  useEffect(() => {
    if (cognitoError && cognitoError.message) {
      setErrors((prevState) => ({ ...prevState, ...{cognitoError: cognitoError.message} }));
    } else {
      setErrors((prevState) => ({ ...prevState, ...{cognitoError: ''} }));
    }
  }, [cognitoError]);

  const handleFieldChange = (event, fieldType) => {
    const data = event.target;
    const trimmedValue = data.value.trim();

    if (data.id === 'emailAddress') {
      setEmailAddress(trimmedValue);
      if (errors['cognitoError']) {
        dispatch(clearCognitoError());
      }
      dispatch({
        type: SET_EMAIL,
        payload: emailAddress
      });
    } else if (data.id === 'newPassword') {
      setNewPassword(trimmedValue);
    } else if (data.id === 'confirmPassword') {
      setConfirmPassword(trimmedValue);
    } else if (data.id === 'verificationCode') {
      setVerificationCode(trimmedValue);
      if (errors['cognitoError'] && cognitoError.code === 'CodeMismatchException') {
        dispatch(clearCognitoError());
      }
    }

    let validationError;

    if (data.id === 'confirmPassword') {
      validationError = validateData(data.id, fieldType, trimmedValue, data.required, newPassword);
    } else {
      validationError = validateData(data.id, fieldType, trimmedValue, data.required);

      if (data.id === 'newPassword' && confirmPassword !== '') {
        let confirmError = validateData('confirmPassword', 'passwordMatch', confirmPassword, true, trimmedValue);
        setErrors((prevState) => ({ ...prevState, ...confirmError }));
      }
    }
    setErrors((prevState) => ({ ...prevState, ...validationError }));

    for (let val in validationError) {
      if (validationError[val] === '')
        setErrors((prevState) => ({
          ...prevState,
          ...validationError,
          [val]: '',
        }));
    }
  };

  const resetErrors = () => {
    setErrors({});
  };

  const handleContinue = () => {
    switch(step) {
      case 'initial':
        dispatch(getPWResetVerificationCode(emailAddress));
        break;
      case 'updating':
        dispatch(resetPassword(emailAddress, verificationCode, newPassword));
        break;
      case 'complete':
      default:
        dispatch(resetPasswordResetStep());
        history.push({
          pathname: '/oauth/sign-in',
          state: { prevPath: history.location.pathname },
        });
        break;
    }
  };

  const handleBackButton = () => {
    resetErrors();
    dispatch(clearCognitoError());
    switch(step) {
      case 'initial':
        history.push({
          pathname: '/oauth/sign-in',
          state: { prevPath: history.location.pathname },
        });
        break;
      case 'updating':
      default:
        setVerificationCode('');
        setNewPassword('');
        setConfirmPassword('');
        dispatch(resetPasswordResetStep());
        break;
    }
  };

  const checkIfErrorFree = () => {
    return !!(Object.values(errors).every((x) => x === true || x === ''));
  };

  const initialStepDisplay = () => {
    return (
      <div className={'screen-container flex-page-full-height-wrapper style-overrides'}>
        <div className={'flex-container flex-column flex-align-stretch flex-nowrap'}>
          <TextInput
              id={'emailAddress'}
              name={'emailAddress'}
              required
              value={emailAddress}
              label={'E-mail'}
              placeholder={'Enter your email'}
              className={'layout-padding-medium-bottom layout-padding-xlarge-top'}
              onChange={(e) => handleFieldChange(e, 'email')}
              onBlur={(e) => handleFieldChange(e, 'email')}
              error={errors.emailAddress}
          />
          <Button
              id={'email-entry'}
              className={'Button Button--primary default__button-label default__button-label--primary'}
              onClick={handleContinue}
              disabled={!emailAddress || !checkIfErrorFree()}>
            Continue
          </Button>

          {
            (errors['cognitoError'] && errors['cognitoError'] !== '') ?
                <div id={'cognito-error'} className={'cta-error layout-padding-small-bottom layout-padding-medium-top'}>
                  {errors['cognitoError']}
                </div>
                :
                null
          }

          <Button
              id={'no-vaccine'}
              className={'Button Button--secondary default__button-label default__button-label--secondary'}
              onClick={handleBackButton}>
            Back to Sign In
          </Button>
        </div>
      </div>
    );
  };

  const updatingStepDisplay = () => {
    return (
      <div className={'screen-container flex-page-full-height-wrapper style-overrides'}>
        <div className={'flex-container flex-column flex-align-stretch flex-nowrap'}>

          <h1 className={'layout-padding-xlarge-vertical text-align-center'}>
            Sent!
          </h1>
          <h5 className={'layout-padding-small-bottom'}>
            We've sent a message to:
          </h5>
          <h5 className={'layout-padding-medium-bottom font-weight-override-700'}>
            {destination}
          </h5>
          <h5 className={'layout-padding-large-bottom'}>
            In a few moments, you will receive a message that contains a verification code to reset your password.
          </h5>

          <TextInput
              id={'verificationCode'}
              name={'verificationCode'}
              required
              value={verificationCode}
              label={'Enter your 6-digit verification code'}
              placeholder={'Enter your 6-digit code'}
              onChange={(e) => handleFieldChange(e, 'verificationCode')}
              onBlur={(e) => handleFieldChange(e, 'verificationCode')}
              error={errors.verificationCode}
          />

          {
            (errors['cognitoError'] && cognitoError.code === 'CodeMismatchException') ?
                <div id={'verification-code-error'} className={'cta-error layout-padding-large-bottom'}>
                  {cognitoError.message}
                </div>
                :
                null
          }

          <TextInput
              id={'newPassword'}
              name={'newPassword'}
              required
              value={newPassword}
              label={'Enter a new password'}
              placeholder={'Enter a new password'}
              togglePw={true}
              onChange={(e) => handleFieldChange(e, 'password')}
              onBlur={(e) => handleFieldChange(e, 'password')}
              error={errors.newPassword}
          />
          <PasswordStrength passwordStrength={errors} />
          <TextInput
              id={'confirmPassword'}
              name={'confirmPassword'}
              required
              value={confirmPassword}
              label={'Confirm your password'}
              placeholder={'Confirm your password'}
              togglePw={true}
              onChange={(e) => handleFieldChange(e, 'passwordMatch')}
              onBlur={(e) => handleFieldChange(e, 'passwordMatch')}
              error={errors.confirmPassword}
          />
          <Button
              id={'password-update'}
              className={'Button Button--primary default__button-label default__button-label--primary'}
              onClick={handleContinue}
              disabled={!verificationCode || !newPassword || !confirmPassword || !checkIfErrorFree()}>
            Continue
          </Button>

          {
            (errors['cognitoError'] && cognitoError.code !== 'CodeMismatchException') ?
                <div id={'cognito-error'} className={'cta-error layout-padding-small-bottom layout-padding-medium-top'}>
                  {cognitoError.message}
                </div>
                :
                null
          }

          <Button
              id={'no-vaccine'}
              className={'Button Button--secondary default__button-label default__button-label--secondary'}
              onClick={handleBackButton}>
            Back to previous step
          </Button>
        </div>
      </div>
    );
  };

  const completeStepDisplay = () => {
    return (
      <div className={'screen-container flex-page-full-height-wrapper style-overrides'}>
        <div className={'flex-container flex-column flex-align-stretch flex-nowrap'}>
          <h1 className={'layout-padding-xlarge-vertical text-align-center'}>
            Done!
          </h1>
          <h5 className={'layout-padding-large-bottom'}>
            Your password has been reset.
          </h5>
          <Button
              id={'reset-complete'}
              className={'Button Button--primary default__button-label default__button-label--primary'}
              onClick={handleContinue}>
            Sign In
          </Button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      { (step === 'initial') ? initialStepDisplay() : null }
      { (step === 'updating') ? updatingStepDisplay() : null }
      { (step === 'complete') ? completeStepDisplay() : null }
    </React.Fragment>
  );
};

export default PasswordReset;
