import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import SignIn from './modules/auth/components/SignIn';
import SignUp from './modules/auth/components/SignUp';
import ProtectedRoute from './modules/auth/components/ProtectedRoute';
import Start from './modules/start/Start';
import PageHeader from './modules/shared/components/PageHeader/PageHeader';
import PageFooter from './modules/shared/components/PageFooter/PageFooter';
import Finding from './modules/finding/components/Finding';
import Consent from './modules/consent/Consent';
import PasswordReset from './modules/auth/components/PasswordReset';
import Retry from './modules/auth/components/Retry';
import ConfirmDataMatch from './modules/confirmDataMatch/components/ConfirmDataMatch';
import RecordNotFound from './modules/immunizations/components/RecordNotFound';
import RecordNotMatching from './modules/immunizations/components/RecordNotMatching';
import ReceivedNoDose from './modules/immunizations/components/ReceivedNoDose';
import Profile from './modules/profile/components/Profile';
import VaccineConfirmationForm from './modules/vaccineConfirmationForm/components/VaccineConfirmationForm';
import ErrorScreen from './modules/error/ErrorScreen';
import VerifyCode from './modules/auth/components/VerifyCode';
import BotcoWebChat from './modules/botco/components/BotcoWebChat';

const App = () => {
  return (
    <div className="App">
      <PageHeader />
      <div className={'main-container flex-fullsize-vertical layout-padding-xlarge-horizontal'}>
        <Router>
          <Switch>
            <Route exact path="/redirect">
              <Start />
            </Route>
            <Route exact path="/oauth/sign-up">
              <SignUp />
            </Route>
            <Route exact path="/oauth/sign-in">
              <SignIn />
            </Route>
            <Route exact path="/oauth/password-reset">
              <PasswordReset />
            </Route>
            <Route exact path="/oauth/confirm-data-match">
              <ConfirmDataMatch />
            </Route>
            <Route exact path="/oauth/profile">
              <Profile />
            </Route>
            <Route exact path="/oauth/error">
              <ErrorScreen />
            </Route>
            <ProtectedRoute exact path="/oauth/protected-profile">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/verify-code">
              <VerifyCode />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/consent">
              <Consent />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/finding">
              <Finding />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/record-not-found">
              <RecordNotFound />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/record-not-matching">
              <RecordNotMatching />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/no-vaccine">
              <ReceivedNoDose />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/vaccine-confirmation-form">
              <VaccineConfirmationForm />
            </ProtectedRoute>
            <ProtectedRoute exact path="/oauth/retry">
              <Retry />
            </ProtectedRoute>
            <Route path="*">
              <Redirect to="/oauth/sign-up" />
            </Route>
          </Switch>
        </Router>
        <BotcoWebChat />
      </div>
      <PageFooter />
    </div>
  );
};

export default App;
