import PropTypes from 'prop-types';
import errorIcon from '../../../theme/assets/awesome-exclamation-circle.svg';

import './Button.scss';

// const Button = props => <button {...props}>{props.children}</button>;

const Button = (props) => {
  return (
    <div className="button-container">
      <button {...props}>{props.children}</button>
      {props.error && (
        <span
          className="input-error"
          style={{ display: 'block', margin: '10px' }}
          id={`${props.id}Error`}
        >
          <img src={errorIcon} alt="errorIcon" style={{ padding: '0px 5px' }} />
          {props.error}
        </span>
      )}
    </div>
  );
};

Button.defaultProps = {
  className: 'Button default__button-label',
  disabled: false,
};

Button.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default Button;
