import axios from 'axios';
import ReactDOM from 'react-dom';
import App from './App';
import store from './redux/store';
import { Provider } from 'react-redux';
import WebFont from 'webfontloader';
import { initialize } from './modules/auth/services/Amplify';
import * as serviceWorker from './serviceWorker';
import { apiEndpoint, backendApiKey } from './settings';

import './App.scss';

axios.defaults.baseURL = apiEndpoint;
axios.interceptors.request.use((config) => {
  config.headers.common['x-api-key'] = backendApiKey;
  return config;
});

initialize();

WebFont.load({
  google: {
    families: [
      'Merriweather:300i,400,400i',
      'Open Sans:400,600,700',
      'Barlow Condensed:300,600',
      'Barlow:500',
      'sans-serif',
    ],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
