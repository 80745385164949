import '../../../theme/styles/global.scss';
import './PageFooter.scss';

const PageFooter = () => {
  return (
    <div className={'page-footer layout-padding-small-vertical'}>
      <span>Powered by Consumer network</span>
    </div>
  );
};

export default PageFooter;
