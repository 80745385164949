/**
 * Options object to be used for setContainerHeight().
 * @typedef {Object} ContainerHeightParams
 * @property {number} [manualHeightOffset] Optional property to use to account for margins in header/footer (These
 *    aren't calculated in offsetHeight method for html elements).
 * @property {boolean} [useHeader] Optional param for whether to add the page header in height calculation.
 * @property {boolean} [useFooter] Optional param for whether to add the page footer in height calculation.
 */

/**
 * Method for calculating app height to be used in flex-box.
 * @param {ContainerHeightParams} options The options to use when calculating app height.
 *
 * @returns {number} The final calculated height offset for the container.
 */
export const setContainerHeight = (options) => {
  const app = document.getElementsByTagName('body');
  const appOffset = app[0].offsetHeight;
  const heightOffset = (options.manualHeightOffset) ? options.manualHeightOffset : 0;
  let headerOffset = 0;
  let footerOffset = 0;

  if (app[0].getElementsByClassName('page-header')) {
    headerOffset = (options.useHeader) ? app[0].getElementsByClassName('page-header')[0].offsetHeight : 0;
  }
  if (app[0].getElementsByClassName('page-footer')) {
    footerOffset = (options.useFooter) ? app[0].getElementsByClassName('page-footer')[0].offsetHeight : 0;
  }

  return appOffset - (headerOffset + footerOffset + heightOffset);
};
