import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../shared/components/Button';
import TextInput from '../../shared/components/TextInput';
import PasswordStrength from './PasswordStrength';
import { validateData } from '../../shared/utilities/validation';
import { useDispatch, useSelector } from 'react-redux';
import { SET_PASSWORD, SET_EMAIL } from '../../../redux/constants';
import '../../theme/styles/global.scss';
import './Auth.scss';

const SignUp = () => {
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    emailAddress: '',
  });
  const { demographics, email } = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  let isFormValidated = !!(
    emailAddress &&
    password &&
    Object.values(errors).every((x) => x === true || x === '')
  );

  useEffect(() => {
    if (demographics && !email) {
      if (demographics.email) {
        setEmailAddress(demographics.email);

        // Validate if the email address received in the url is valid
        let emailError = validateData(
          'emailAddress',
          'email',
          demographics.email
        );
        setErrors((preErrors) => ({ ...preErrors, ...emailError }));
      }
    } else if (email) {
      setEmailAddress(email);

      let emailError = validateData('emailAddress', 'email', email);
      setErrors((preErrors) => ({ ...preErrors, ...emailError }));
    }
  }, []);

  const handleFieldChange = (event, fieldType) => {
    const data = event.target;
    const trimmedValue = data.value.trim();

    if (data.id === 'emailAddress') {
      setEmailAddress(trimmedValue);
    } else if (data.id === 'password') {
      setPassword(trimmedValue);
    }

    const validationError = validateData(
      data.id,
      fieldType,
      trimmedValue,
      data.required
    );
    setErrors((prevState) => ({ ...prevState, ...validationError }));

    for (let val in validationError) {
      if (validationError[val] === '')
        setErrors((prevState) => ({
          ...prevState,
          ...validationError,
          [val]: '',
        }));
    }
  };

  const handleRedirect = (path) => {
    if (path === 'confirm-data-match') {
      dispatch({
        type: SET_PASSWORD,
        payload: password,
      });
      dispatch({
        type: SET_EMAIL,
        payload: emailAddress,
      });
      history.push({
        pathname: '/oauth/confirm-data-match',
        state: { prevPath: history.location.pathname },
      });
    } else if (path === 'sign-in') {
      dispatch({
        type: SET_EMAIL,
        payload: emailAddress,
      });
      history.push({
        pathname: '/oauth/sign-in',
        state: { prevPath: history.location.pathname },
      });
    }
  };

  return (
    <div className={'screen-container style-overrides'}>
      <h4 className={'layout-padding-large-bottom layout-padding-xlarge-top'}>
        You need to create an account to continue.
      </h4>

      <TextInput
        id={'emailAddress'}
        name={'emailAddress'}
        required
        value={emailAddress}
        label={'E-mail'}
        placeholder={'Enter your email address'}
        onChange={(e) => handleFieldChange(e, 'email')}
        onBlur={(e) => handleFieldChange(e, 'email')}
        error={errors.emailAddress}
      />

      <TextInput
        id={'password'}
        name={'password'}
        required
        value={password}
        label={'Create a password'}
        placeholder={'Enter a password'}
        togglePw={true}
        onChange={(e) => handleFieldChange(e, 'password')}
        onBlur={(e) => handleFieldChange(e, 'password')}
        error={errors.password}
      />

      <PasswordStrength passwordStrength={errors} />

      <div className={'auth-terms__container'}>
        <p className={'auth-terms'}>
          *By creating an account you agree to our{' '}
          <a
            className={'auth-terms-link'}
            href="https://app.myirmobile.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          <span> and </span>
          <a
            href="https://app.myirmobile.com/privacy-policy"
            className={'auth-terms-link'}
            id={'privacyPolicy'}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>

      <div className={'layout-padding-medium-vertical'}>
        <Button
          id={'signUpButton'}
          className={
            'Button Button--primary default__button-label default__button-label--primary'
          }
          onClick={() => handleRedirect('confirm-data-match')}
          disabled={!isFormValidated}
        >
          Sign Up
        </Button>
      </div>

      <h5 className={'layout-padding-medium-top'}>Have a MyIR account?</h5>

      <Button
        id={'sign-in-redirect'}
        className={'Button default__button-label Button__border-primary'}
        onClick={() => handleRedirect('sign-in')}
      >
        Sign In
      </Button>
    </div>
  );
};

export default SignUp;
