import Radio from '../../shared/components/RadioButton/index';
import errorIcon from '../../theme/assets/awesome-exclamation-circle.svg';

const Gender = (props) => {
  return (
    <div className="gender-container">
      <label className="FormTextInput__label">Gender (Optional)</label>
      <div className="select-gender">
        <Radio
          type="radio"
          id="female"
          label="Female"
          checked={props.gender === 'F'}
          onChange={() => props.setGender('F')}
        />
        <Radio
          type="radio"
          id="male"
          label="Male"
          checked={props.gender === 'M'}
          onChange={() => props.setGender('M')}
        />
        <Radio
          type="radio"
          id="other"
          label="Other"
          checked={props.gender === 'O'}
          onChange={() => props.setGender('O')}
        />
      </div>
      {props.error && (
        <span className="input-error" id={`${props.id}Error`}>
          <img src={errorIcon} alt="errorIcon" style={{ padding: '0px 5px' }} />
          {props.error}
        </span>
      )}
    </div>
  );
};

export default Gender;
