import './BotcoWebChat.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useScript from '../../shared/utilities/useScript';

const BotcoWebChat = () => {
  const [loaded, error] = useScript(
    'https://widget.botco.ai/1.0.5/20200827/widget.js'
  );

  const params = useSelector((state) => state.botco.params);

  useEffect(() => {
    if (error) {
      console.log('Error loading Botco script');
    }

    if (!loaded) return;

    try {
      window.BotcoWebchat.mount(params);
    } catch (err) {
      console.log('botco mount error', err);
    }

    // Cleanup
    return () => {
      window.BotcoWebchat &&
        window.BotcoWebchat.el &&
        window.BotcoWebchat.unmount();
    };
  }, [loaded, error, params]);

  return null;
};

BotcoWebChat.propTypes = {
  params: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    referralCode: PropTypes.string,
  }),
};

export default BotcoWebChat;
