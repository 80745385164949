export const transformLoadedStatesToUI = (states) => {
  let returnData = [];

  states.forEach((state) => {
    const name = state.name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    returnData.push({
      id: state.id,
      stateCode: state.registryStateCode,
      title: name,
      certificate: state.stateCertificate,
    });
  });

  return returnData;
};

export const concealedPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/.(?=.{4})/g, '*');
};
