import { useState } from 'react';
import PropTypes from 'prop-types';
import errorIcon from '../../../theme/assets/awesome-exclamation-circle.svg';
import { ReactComponent as MaterialIconVisibility } from '../../../theme/assets/material-icons/visibility_grey.svg';
import { ReactComponent as MaterialIconVisibilityOff } from '../../../theme/assets/material-icons/visibility_off_grey.svg';
import '../../../theme/styles/global.scss';
import './TextInput.scss';

const TextInput = (props) => {
  const [hidePassword, setHidePassword] = useState(true);
  const errorClass = props.error ? 'FormTextInput__textBox--error' : '';
  const onEnter = (e, callback) => e.key === 'Enter' && callback();

  return (
    <div className={props.className}>
      <div className="FormTextInput-container">
        <label htmlFor={props.label} className="FormTextInput__label">
          {props.label}
        </label>
        <div className="FormTextInput__textBoxContainer">
          <input
            className={`FormTextInput__textBox ${errorClass}`}
            id={props.id}
            name={props.name}
            type={
              props.togglePw ? (hidePassword ? 'password' : 'text') : props.type
            }
            value={props.value}
            onChange={props.onChange}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            maxLength={props.maxLength}
            disabled={props.disabled}
            onKeyDown={
              props.handleEnter ? (e) => onEnter(e, props.handleEnter) : null
            }
            onBlur={props.onBlur ? (e) => props.onBlur(e) : null}
            onClick={props.onClick ? (e) => props.onClick(e) : null}
          />
          {props.togglePw && (
            <button
              type="button"
              className="togglePassword"
              onClick={() => setHidePassword(!hidePassword)}
            >
              {hidePassword ? (
                <MaterialIconVisibility />
              ) : (
                <MaterialIconVisibilityOff />
              )}
            </button>
          )}
        </div>
        {props.error && (
          <span className="input-error" id={`${props.id}Error`}>
            <img
              src={errorIcon}
              alt="errorIcon"
              style={{ padding: '0px 5px' }}
            />
            {props.error}
          </span>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password', 'emailAddress', 'date']),
  className: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  togglePw: PropTypes.bool,
};

export default TextInput;
