import moment from 'moment';
import {
  emailAddressRegex,
  passwordRegex,
  postalCodeRegex,
  cityRegex,
  phnRegex,
  isNameValid,
  verificationCodeCheck,
} from './regex';

const VALIDATION_ERRORS = {
  REQUIRED_FIELD: 'Required field.',
  NAME: 'Please enter a valid name.',
  EMAIL: 'Please enter a valid email address.',
  PASSWORD: 'Please enter a valid password.',
  DATE: 'Please enter a valid date.',
  DATE_18: 'You must be 18 or older.',
  ZIP_CODE: 'Invalid zip code.',
  CITY: 'Invalid city',
  PHONE_NUMBER: 'Not a valid phone number.',
  VERIFICATION_CODE: 'Please enter valid verification code',
  PASSWORD_MATCH: 'The entered passwords do not match',
};

/**
 * Method to replace the old validation function. This one checks by type instead of id, as well as accounting for
 *    whether the data is required or not.
 *
 * NOTE: To update existing fields: Update the method that handles the field change to run this check, and call that
 *    method from both the onClick AND the onBlur attribute of the field you are updating. See signUp.js or
 *    signIn.js for examples of usage. The structure of the local errors object in the component was preserved to
 *    make this a smaller and simpler transition, but can be updated later if necessary.
 *
 * @param {string} id The id of the field to check
 * @param {string} type The type of data to check. Types are listed in the switch below.
 * @param {any} value The data to check
 * @param {boolean} [required] Optional param for whether the field is required.
 * @param {any} [compareData] Optional data to compare the value against for a match.
 *
 * @returns The object containing all relevant error data for the field being checked.
 */
export function validateData(id, type, value, required, compareData) {
  let error = {};

  if (required && (!value || value === '')) {
    error[id] = VALIDATION_ERRORS.REQUIRED_FIELD;
  } else {
    switch (type) {
      case 'name':
        error[id] = !isNameValid(value) ? VALIDATION_ERRORS.NAME : '';
        break;
      case 'email':
        error[id] = !emailAddressRegex(value) ? VALIDATION_ERRORS.EMAIL : '';
        break;
      case 'password':
        const rules = passwordRegex(value);
        error = rules;
        error[id] = !(rules.characters && rules.uppercase && rules.number)
          ? VALIDATION_ERRORS.PASSWORD
          : '';
        break;
      case 'date':
      case 'date-18':
        if (value === 'Invalid date') {
          error[id] = VALIDATION_ERRORS.DATE;
        } else {
          let under18 = moment().diff(moment(value), 'years', true) < 18;
          if (type === 'date-18') {
            error[id] = under18 ? VALIDATION_ERRORS.DATE_18 : '';
          }
        }
        break;
      case 'zipCode':
        error[id] = !postalCodeRegex(value) ? VALIDATION_ERRORS.ZIP_CODE : '';
        break;
      case 'city':
        error[id] = !cityRegex(value) ? VALIDATION_ERRORS.CITY : '';
        break;
      case 'phoneNumber':
        error[id] = !phnRegex(value) ? VALIDATION_ERRORS.PHONE_NUMBER : '';
        break;
      case 'verificationCode':
        error[id] = !verificationCodeCheck(value)
          ? VALIDATION_ERRORS.VERIFICATION_CODE
          : '';
        break;
      case 'passwordMatch':
        error[id] =
          value.toString() !== compareData.toString()
            ? VALIDATION_ERRORS.PASSWORD_MATCH
            : '';
        break;
      case 'notEmpty':
        error[id] =
          !value || value === '' ? VALIDATION_ERRORS.REQUIRED_FIELD : '';
        break;
      default:
        break;
    }
  }

  return error;
}

/**
 * Old method to be replaced by validateData.
 */
export default function validation(id, value) {
  let error = {};
  if (id === 'firstName' || id === 'lastName') {
    if (!isNameValid(value) || value === '') {
      error[id] = `Please enter a valid ${id}.`;
    } else error[id] = '';
  } else if (id === 'emailAddress') {
    if (!emailAddressRegex(value)) {
      error[id] = 'Please enter a valid email.';
    } else error[id] = '';
  } else if (id === 'password') {
    const regexError = passwordRegex(value);
    error = regexError;
    if (
      (regexError.characters && regexError.uppercase && regexError.number) !==
      true
    ) {
      error.passwordError = 'Please enter a valid password';
    } else {
      error.passwordError = '';
    }
  } else if (id === 'dob-picker' || id === 'dob') {
    if (!value || value === '' || value === 'Invalid date') {
      error.dob = 'Required field';
    } else if (moment().diff(moment(value), 'years', true) < 18) {
      error.dob = 'You must be 18 or older';
    } else error.dob = '';
  } else if (id === 'zipCode' || id === 'zip') {
    if (!postalCodeRegex(value) && value !== '') {
      error[id] = 'Invalid zip code';
    } else error[id] = '';
  } else if (id === 'city') {
    if (!cityRegex(value) && value !== '') {
      error[id] = 'Invalid city';
    } else error[id] = '';
  } else if (id.startsWith('phone') && !id.endsWith('phoneType')) {
    if (!phnRegex(value)) {
      error[id] = 'Not a valid phone number';
    } else error[id] = '';
  }

  // Address is optional for now

  // else if (id === 'address') {
  //   if (value.length > 0) {
  //     error[id] = '';
  //   } else error[id] = 'Required field';
  // } else if (id === 'gradeLevel') {
  //   if (!value) {
  //     error[id] = 'Required field';
  //   } else error[id] = '';
  // }
  return error;
}

export const hasRequired = (required, errors, phoneNumbers) => {
  const hasPhone = phoneNumbers
    ? phoneNumbers.every((phone) => phone.number && phone.phoneType)
    : true;
  let validationErrs = {};
  for (let val in required) {
    let txtErr = validation(val, required[val]);
    validationErrs = { ...validationErrs, ...txtErr };
    if (!required[val]) {
      validationErrs = {
        ...errors,
        ...validationErrs,
        [val]: 'Required field.',
      };
    } else if (errors[val] || validationErrs[val]) {
      validationErrs = { ...errors, ...validationErrs };
    } else {
      validationErrs = { ...errors, ...validationErrs, [val]: '' };
    }
  }
  if (
    Object.values(validationErrs).every((x) => x === true || x === '') &&
    hasPhone
  ) {
    return { valid: true, errors: { ...errors, ...validationErrs } };
  } else {
    return { valid: false, errors: { ...errors, ...validationErrs } };
  }
};

export function objectComparison(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !objectComparison(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

function isObject(object) {
  return object != null && typeof object === 'object';
}
