import {
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
} from '../constants';
import { sortObjArrayByProp } from '../../modules/shared/utilities/sorts';

const initialState = {
  loading: false,
  loaded: false,
  states: [],
};

export const externalDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATES_REQUEST:
      return {
        loading: true,
        loaded: false,
        states: state.states,
      };
    case GET_STATES_SUCCESS:
      const sortedData = sortObjArrayByProp(action.payload.data.payload, 'name');
      return {
        loading: false,
        loaded: true,
        states: sortedData,
      };
    case GET_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
