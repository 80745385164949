import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SET_QUERY_PARAMS,
  CLEANUP_LOGIN_FLOW,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  SET_PASSWORD,
  SET_EMAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_DEMOGRAPHICS_SUCCESS,
  UPDATE_USER_DEMOGRAPHICS_FAILURE,
  CLEAR_COGNITO_ERROR,
  INCREMENT_MATCHING_ATTEMPT_SUCCESS,
  SET_COMING_FROM_CREATE_FALSE,
  GET_PW_RESET_VERIFICATION_CODE_REQUEST,
  GET_PW_RESET_VERIFICATION_CODE_SUCCESS,
  GET_PW_RESET_VERIFICATION_CODE_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_RESET_STEP,
  UPDATE_DEMOGRAPHICS_EMAIL,
} from '../constants';

const initialState = {
  loading: false,
  error: false,
  user: {
    demographics: null,
    auth: false,
    history: null,
    requiresVerification: false,
    comingFromLoginFlow: true,
    passwordResetStep: 'initial',
  },
  scope: false,
  client: null,
  attempts: 0,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        user: { ...state.user },
        scope: state.scope,
        client: { ...state.client },
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        error: false,
        user: { ...state.user, auth: action.payload },
        scope: state.scope,
        client: { ...state.client },
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: { ...state.user },
        error: action.payload,
      };
    case SET_QUERY_PARAMS:
      return {
        ...state,
        loading: false,
        error: false,
        user: { ...state.user, demographics: action.payload.userInfo },
        client: { ...action.payload.client },
        scope: action.payload.scope,
      };
    case SET_PASSWORD:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          demographics: state.user.demographics,
          password: action.payload,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case SET_EMAIL:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          demographics: state.user.demographics,
          password: state.user.password,
          email: action.payload,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case UPDATE_DEMOGRAPHICS_EMAIL:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          auth: state.user.auth,
          demographics: {
            ...state.user.demographics,
            email: action.payload,
          },
          email: state.user.email,
          password: state.user.password,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case GET_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        user: { ...state.user },
        scope: state.scope,
        client: { ...state.client },
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          history: action.payload,
          personId: action.payload.person.personId,
          userId: action.payload.person.userId,
        },
        scope: state.scope,
        client: { ...state.client },
      };
    case GET_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        user: { ...state.user },
        error: action.payload,
        scope: state.scope,
        client: { ...state.client },
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        user: {
          demographics: state.user.demographics,
          password: state.user.password,
          ...state.user,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          cognitoId: action.payload.cognitoId,
          personId: action.payload.personId,
          userId: action.payload.userId,
          auth: { attributes: { sub: action.payload.cognitoId } },
          comingFromCreateUserFlow: true,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        user: {
          ...state.user,
          demographics: state.user.demographics,
          password: state.user.password,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          requiresVerification: true,
          history: null,
        },
        client: { ...state.client },
        scope: state.scope,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        user: { ...state.user, history: null },
      };
    case UPDATE_USER_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          demographics: {
            ...action.payload.data.userDemographics,
          },
          requiresVerification: action.payload.data.requiresVerification,
        },
      };
    case UPDATE_USER_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_COGNITO_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case INCREMENT_MATCHING_ATTEMPT_SUCCESS:
      return {
        ...state,
        attempts: state.attempts + 1,
      };
    case SET_COMING_FROM_CREATE_FALSE:
      return {
        ...state,
        user: {
          ...state.user,
          comingFromCreateUserFlow: false,
        },
      };
    case CLEANUP_LOGIN_FLOW:
      return {
        ...state,
        user: { ...state.user, comingFromLoginFlow: false },
      };
    case GET_PW_RESET_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_PW_RESET_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          passwordResetStep: 'updating',
          deliveryMedium: action.payload,
        },
      };
    case GET_PW_RESET_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: {
          ...state.user,
          passwordResetStep: 'complete',
        },
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASSWORD_RESET_STEP:
      return {
        ...state,
        user: {
          ...state.user,
          passwordResetStep: 'initial',
        },
      };
    default:
      return state;
  }
};
