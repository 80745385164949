const Retry = () => {
  return (
    <p>
      We couldn't find your vaccine info. <br />
      Your [data] does not match. <br />
      Update your information and try again
    </p>
  );
};
export default Retry;
