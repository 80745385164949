import Amplify from 'aws-amplify';
import {
  cognitoRegion,
  cognitoClientId,
  cognitoUserPoolId,
} from '../../../settings';

export function initialize() {
  Amplify.configure({
    Auth: {
      userPoolId: cognitoUserPoolId,
      region: cognitoRegion,
      userPoolWebClientId: cognitoClientId,
    },
  });
}
