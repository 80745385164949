import { useState } from 'react';
import PropTypes from 'prop-types';
import errorIcon from '../../../theme/assets/awesome-exclamation-circle.svg';
import { ReactComponent as Landline } from '../../../theme/assets/Phone.svg';
import { ReactComponent as MobilePhone } from '../../../theme/assets/MobilePhone.svg';
import '../../../theme/styles/global.scss';
import './PhoneInput.scss';

const PhoneInput = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    const errorClass = props.error ? 'PhoneInput__textBox--error' : '';
    const onEnter = (e, callback) => e.key === 'Enter' && callback();


    function handleOnClick(item) {
        props.onChangePhoneType(item.id)
        setDropdownOpen(false)
    }

    const items = [
        {
            id: 'LANDLINE',
            value: <Landline />,
        },
        {
            id: 'MOBILE',
            value: <MobilePhone />,
        }
    ];


    return (
        <div className={props.className}>
            <div className="PhoneInput-container">
                <label htmlFor={props.label} className="PhoneInput__label">
                    {props.label}
                </label>
                <div className="PhoneInput__textBoxContainer">
                    <div className="dropdown">
                        <button className="dropbtn"
                            onClick={() => toggle(!dropdownOpen)}
                        >{props.phoneType && props.phoneType === 'MOBILE' ? <MobilePhone /> : <Landline />}</button>

                        {dropdownOpen && (<div className="dropdown-content">
                            {items.map(item => (
                                <button type="button" key={item.id} onClick={() => handleOnClick(item)}>
                                    <span>{item.value}</span>
                                </button>
                            ))}
                        </div>)}

                    </div>

                    <input
                        className={`PhoneInput__textBox ${errorClass}`}
                        id={props.id}
                        name={props.name}
                        type='phone'
                        value={props.value}
                        onChange={props.onChange}
                        placeholder={props.placeholder}
                        autoFocus={props.autoFocus}
                        maxLength={props.maxLength}
                        disabled={props.disabled}
                        onKeyDown={
                            props.handleEnter ? (e) => onEnter(e, props.handleEnter) : null
                        }
                        onBlur={props.onBlur ? (e) => props.onBlur(e) : null}
                        onClick={props.onClick ? (e) => props.onClick(e) : null}
                    />
                </div>
                {
                    props.error && (
                        <span className="input-error" id={`${props.id}Error`}>
                            <img
                                src={errorIcon}
                                alt="errorIcon"
                                style={{ padding: '0px 5px' }}
                            />
                            {props.error}
                        </span>
                    )
                }
            </div >
        </div >
    );
};

PhoneInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    phoneType: PropTypes.oneOf(['MOBILE', 'LANDLINE', '', 'mobile', 'landline']),
    onChangePhoneType: PropTypes.func,
};

export default PhoneInput;
