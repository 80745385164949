import {
  POST_CONSENT_REQUEST,
  POST_CONSENT_SUCCESS,
  POST_CONSENT_FAILURE,
} from '../constants';

export const consentReducer = (
  state = { loaded: false, loading: false, redirect_url: null },
  action
) => {
  switch (action.type) {
    case POST_CONSENT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case POST_CONSENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        redirect_url: action.payload,
      };
    case POST_CONSENT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
