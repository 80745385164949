/**
 * Regex to validate any name, whether for a person or a place, and allows the following:
 *    a-z, A-Z, space, dash, period, apostrophe, and foreign characters (i.e: è, ä, ñ, ç, etc.)
 *    Does not allow the name to end with a dash, period, or apostrophe.
 */
export const isNameValid = (name) => {
  const regex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
  return regex.test(name);
};

/**
 * Options object to be used for characterCount().
 * @typedef {Object} CharacterCountParams
 * @property {number} [exactly] Optional property to define the exact expected length. Overrides other props.
 * @property {number} [min] Optional param for minimum expected length.
 * @property {number} [max] Optional param for maximum expected length.
 */
/**
 * Regex for determining if data has a character count within supplied parameters.
 * @param {any} data The data to confirm character count on. This data is converted to a string before checking.
 * @param {CharacterCountParams} params The configuration to use when checking character count.
 *
 * @returns {boolean} Whether the character count is within the supplied parameters or not.
 */
export const characterCount = (data, params) => {
  let returnData = null;
  let dataLength = data.toString().length;

  if (params.hasOwnProperty('exactly')) {
    returnData = dataLength === params.exactly;
  } else if (params.hasOwnProperty('min') && params.hasOwnProperty('max')) {
    returnData = dataLength >= params.min && dataLength <= params.max;
  } else if (params.hasOwnProperty('min')) {
    returnData = dataLength >= params.min;
  } else if (params.hasOwnProperty('max')) {
    returnData = dataLength <= params.max;
  }

  return returnData;
};

export const verificationCodeCheck = (data) => {
  let transformedData = digitsOnly(data);
  return characterCount(transformedData, {exactly: 6});
};

export const emailAddressRegex = (emailAddress) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(String(emailAddress).toLowerCase());
};

export const nameRegex = (name) => {
  const regexName = /^[a-zA-Z-' ]*$/;
  return regexName.test(name);
};

export const validPasswordRegex = (password) => {
  var regexPassword =
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})';
  return regexPassword.test(password);
};

/** Strip all non-digit characters from a string */
export const digitsOnly = (num) => {
  return ('' + num).replace(/\D/g, '');
};

export const phoneFormatter = (phoneNumberString) => {
  const cleaned = digitsOnly(phoneNumberString);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumberString;
};

/** Postal codes with 5 or 9 digits */
const postalCodeRegExp = new RegExp(/^(\d{5})([-]?\d{4})?$/);

export const postalCodeFormatter = (postalCode) => {
  const cleaned = digitsOnly(postalCode);
  const match = cleaned.match(postalCodeRegExp);

  if (match) {
    // Join the non-empty matches by a dash
    return [match[1], match[2]].filter(Boolean).join('-');
  }

  return cleaned;
};

export const postalCodeRegex = (postalCode) => {
  return postalCodeRegExp.test(postalCode);
};

export const passwordRegex = (password) => {
  const passwordStrengthObj = {};
  const passwordRegexObj = {
    characters: /.{8,}/,
    uppercase: /(?=.*[A-Z])/,
    number: /\d/g,
  };

  Object.keys(passwordRegexObj).forEach((regexKey) => {
    passwordStrengthObj[regexKey] = passwordRegexObj[regexKey].test(password);
  });
  return passwordStrengthObj;
};

export const cityRegex = (city) => {
  let regex = /^[a-zA-Z -]+$/;
  return regex.test(city);
};

export const phnRegex = (num) => {
  let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return regex.test(num);
};

export const dateFormat = (date) => {
  const cleaned = ('' + date).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})$/);
  if (match) {
    return match[1] + '/' + match[2] + '/' + match[3];
  }
  return date;
};

export const parsePhoneNumber = (phone) =>
  phone
    ? {
        areaCode: phone.substring(0, 3),
        phoneNumber: phone.substring(3),
        countryCode: '+1',
      }
    : null;
