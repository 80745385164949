// Users
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';
export const SET_STATE_SELECTED = 'SET_STATE_SELECTED';
export const CLEANUP_LOGIN_FLOW = 'CLEANUP_LOGIN_FLOW';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_EMAIL = 'SET_EMAIL';
export const CLEAR_COGNITO_ERROR = 'CLEAR_COGNITO_ERROR';
export const UPDATE_DEMOGRAPHICS_EMAIL = 'UPDATE_DEMOGRAPHICS_EMAIL';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';

export const GET_MATCH_REQUEST = 'GET_MATCH_REQUEST';
export const GET_MATCH_SUCCESS = 'GET_MATCH_SUCCESS';
export const GET_MATCH_FAILURE = 'GET_MATCH_FAILURE';
export const RESET_MATCH_LOADED = 'RESET_MATCH_LOADED';
export const SET_COMING_FROM_CREATE_FALSE = 'SET_COMING_FROM_CREATE_FALSE';

export const UPDATE_USER_DEMOGRAPHICS_SUCCESS =
  'UPDATE_USER_DEMOGRAPHICS_SUCCESS';
export const UPDATE_USER_DEMOGRAPHICS_FAILURE =
  'UPDATE_USER_DEMOGRAPHICS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

// Consent
export const POST_CONSENT_REQUEST = 'POST_CONSENT_REQUEST';
export const POST_CONSENT_SUCCESS = 'POST_CONSENT_SUCCESS';
export const POST_CONSENT_FAILURE = 'POST_CONSENT_FAILURE';

// External Data
export const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export const GET_VERIFICATION_CODE_REQUEST = 'GET_VERIFICATION_CODE_REQUEST';
export const GET_VERIFICATION_CODE_SUCCESS = 'GET_VERIFICATION_CODE_SUCCESS';
export const GET_VERIFICATION_CODE_FAILURE = 'GET_VERIFICATION_CODE_FAILURE';
export const CONFIRM_VERIFICATION_CODE_REQUEST =
  'CONFIRM_VERIFICATION_CODE_REQUEST';
export const CONFIRM_VERIFICATION_CODE_SUCCESS =
  'CONFIRM_VERIFICATION_CODE_SUCCESS';
export const CONFIRM_VERIFICATION_CODE_FAILURE =
  'CONFIRM_VERIFICATION_CODE_FAILURE';

export const INCREMENT_MATCHING_ATTEMPT_SUCCESS =
  'INCREMENT_MATCHING_ATTEMPT_SUCCESS';

/* BOTCO WEB CHAT */
export const SET_BOTCO_REFERRAL_CODE = 'SET_BOTCO_REFERRAL_CODE';

export const GET_PW_RESET_VERIFICATION_CODE_REQUEST = 'GET_PW_RESET_VERIFICATION_CODE_REQUEST';
export const GET_PW_RESET_VERIFICATION_CODE_SUCCESS = 'GET_PW_RESET_VERIFICATION_CODE_SUCCESS';
export const GET_PW_RESET_VERIFICATION_CODE_FAILURE = 'GET_PW_RESET_VERIFICATION_CODE_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_RESET_STEP = 'RESET_PASSWORD_RESET_STEP';
