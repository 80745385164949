import magnifyingGlass from '../../../theme/assets/animation_Loader.gif';
import './Loading.scss';

const Loading = (props) => {
  const { className } = props;
  const loadingClasses = 'spinner-container' + (className ? (' ' + className) : '');

  return (
    <div className={loadingClasses}>
      <img
        src={magnifyingGlass}
        alt={'loading'}
        id={'loading-spinner'}
        />
      {
        (props && props.loadingMsg) && <p>{props.loadingMsg}</p>
      }
    </div>
  );
};

export default Loading;
