import axios from 'axios';
import {
  POST_CONSENT_REQUEST,
  POST_CONSENT_SUCCESS,
  POST_CONSENT_FAILURE,
} from '../constants';

export const postConsent = (consentData) => async (dispatch) => {
  try {
    dispatch({ type: POST_CONSENT_REQUEST });
    const { data } = await axios.post(`/consent`, consentData);

    dispatch({ type: POST_CONSENT_SUCCESS, payload: data.url });
  } catch (error) {
    if (error.response && error.response.data) {
      dispatch({
        type: POST_CONSENT_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: POST_CONSENT_FAILURE,
        payload: error.message,
      });
    }
  }
};
