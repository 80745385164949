import PropTypes from 'prop-types';
import './RadioButton.scss';

export const RadioButton = ({
  id,
  checked,
  label,
  value,
  onChange,
  className,
  handleEnter,
  name,
}) => {
  const onEnter = (e, callback) => e.key === 'Enter' && callback();

  return (
    <div
      className = {
        className === 'styled-radio' ? 'styled-radio-container' : 'radio-item'
      }
      onClick={() => onChange(value)}
    >
      {className === 'styled-radio' && (
        <label htmlFor={id} className="styled-radio-name">
          {label}
        </label>
      )}
      <input
        name={name}
        checked={checked}
        id={id}
        className={className ? className : 'basic-radio'}
        type="radio"
        value={value}
        onChange={() => onChange(value)}
        onKeyDown={handleEnter ? (e) => onEnter(e, handleEnter) : null}
      />
      {className !== 'styled-radio' && (
        <label htmlFor={id} className="form-radio-label">
          {label}
        </label>
      )}
    </div>
  );
};

RadioButton.defaultProps = {
  checked: false,
};

RadioButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default RadioButton;
