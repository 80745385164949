import {
  GET_VERIFICATION_CODE_REQUEST,
  GET_VERIFICATION_CODE_SUCCESS,
  GET_VERIFICATION_CODE_FAILURE,
  CONFIRM_VERIFICATION_CODE_REQUEST,
  CONFIRM_VERIFICATION_CODE_SUCCESS,
  CONFIRM_VERIFICATION_CODE_FAILURE,
} from '../constants';
import axios from 'axios';

export function getVerificationCode(phoneData) {
  return async dispatch => {
    dispatch({
      type: GET_VERIFICATION_CODE_REQUEST,
      payload: phoneData,
    });

    try {
      const {
        userId,
        personId,
        countryCode,
        areaCode,
        phoneNumber,
        phoneType,
        phoneId = null,
      } = phoneData;

      const {data} = await axios.get(`/account/verify?userId=${userId}&personId=${personId}&countryCode=${countryCode}&areaCode=${areaCode}&phoneNumber=${phoneNumber}&phoneType=${phoneType}&phoneId=${phoneId}`);

      dispatch({
        type: GET_VERIFICATION_CODE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_VERIFICATION_CODE_FAILURE,
        payload: err.message,
      });
    }
  };
}

export function confirmVerificationCode(confirmationData) {
  return async dispatch => {
    dispatch({
      type: CONFIRM_VERIFICATION_CODE_REQUEST,
      payload: confirmationData,
    });
    try {

      const response = await axios.post('/account/verify', confirmationData);
      
      if (response.status === 200) {
        dispatch({
          type: CONFIRM_VERIFICATION_CODE_SUCCESS,
          payload: response.data.payload,
        });
      } else {
        dispatch({
          type: CONFIRM_VERIFICATION_CODE_FAILURE,
          payload: response.data.message,
        });
      }
    } catch (err) {
      dispatch({
        type: CONFIRM_VERIFICATION_CODE_FAILURE,
        payload: err.message,
      });
    }
  };
}