export const states = [
  {
    id: 'AL',
    title: 'AL',
    country: 'US',
  },
  {
    id: 'AK',
    title: 'AK',
    country: 'US',
  },
  {
    id: 'AZ',
    title: 'AZ',
    country: 'US',
  },
  {
    id: 'AR',
    title: 'AR',
    country: 'US',
  },
  {
    id: 'CA',
    title: 'CA',
    country: 'US',
  },
  {
    id: 'CO',
    title: 'CO',
    country: 'US',
  },
  {
    id: 'CT',
    title: 'CT',
    country: 'US',
  },
  {
    id: 'DC',
    title: 'DC',
    country: 'US',
  },
  {
    id: 'DE',
    title: 'DE',
    country: 'US',
  },
  {
    id: 'FL',
    title: 'FL',
    country: 'US',
  },
  {
    id: 'GA',
    title: 'GA',
    country: 'US',
  },
  {
    id: 'HI',
    title: 'HI',
    country: 'US',
  },
  {
    id: 'ID',
    title: 'ID',
    country: 'US',
  },
  {
    id: 'IL',
    title: 'IL',
    country: 'US',
  },
  {
    id: 'IN',
    title: 'IN',
    country: 'US',
  },
  {
    id: 'IA',
    title: 'IA',
    country: 'US',
  },
  {
    id: 'KS',
    title: 'KS',
    country: 'US',
  },
  {
    id: 'KY',
    title: 'KY',
    country: 'US',
  },
  {
    id: 'LA',
    title: 'LA',
    country: 'US',
  },
  {
    id: 'ME',
    title: 'ME',
    country: 'US',
  },
  {
    id: 'MD',
    title: 'MD',
    country: 'US',
  },
  {
    id: 'MA',
    title: 'MA',
    country: 'US',
  },
  {
    id: 'MI',
    title: 'MI',
    country: 'US',
  },
  {
    id: 'MN',
    title: 'MN',
    country: 'US',
  },
  {
    id: 'MS',
    title: 'MS',
    country: 'US',
  },
  {
    id: 'MO',
    title: 'MO',
    country: 'US',
  },
  {
    id: 'MT',
    title: 'MT',
    country: 'US',
  },
  {
    id: 'NE',
    title: 'NE',
    country: 'US',
  },
  {
    id: 'NV',
    title: 'NV',
    country: 'US',
  },
  {
    id: 'NH',
    title: 'NH',
    country: 'US',
  },
  {
    id: 'NJ',
    title: 'NJ',
    country: 'US',
  },
  {
    id: 'NM',
    title: 'NM',
    country: 'US',
  },
  {
    id: 'NY',
    title: 'NY',
    country: 'US',
  },
  {
    id: 'NC',
    title: 'NC',
    country: 'US',
  },
  {
    id: 'ND',
    title: 'ND',
    country: 'US',
  },
  {
    id: 'OH',
    title: 'OH',
    country: 'US',
  },
  {
    id: 'OK',
    title: 'OK',
    country: 'US',
  },
  {
    id: 'OR',
    title: 'OR',
    country: 'US',
  },
  {
    id: 'PA',
    title: 'PA',
    country: 'US',
  },
  {
    id: 'RI',
    title: 'RI',
    country: 'US',
  },
  {
    id: 'SC',
    title: 'SC',
    country: 'US',
  },
  {
    id: 'SD',
    title: 'SD',
    country: 'US',
  },
  {
    id: 'TN',
    title: 'TN',
    country: 'US',
  },
  {
    id: 'TX',
    title: 'TX',
    country: 'US',
  },
  {
    id: 'UT',
    title: 'UT',
    country: 'US',
  },
  {
    id: 'VT',
    title: 'VT',
    country: 'US',
  },
  {
    id: 'VA',
    title: 'VA',
    country: 'US',
  },
  {
    id: 'WA',
    title: 'WA',
    country: 'US',
  },
  {
    id: 'WV',
    title: 'WV',
    country: 'US',
  },
  {
    id: 'WI',
    title: 'WI',
    country: 'US',
  },
  {
    id: 'WY',
    title: 'WY',
    country: 'US',
  },
  {
    id: 'AS',
    title: 'AS',
    country: 'US',
  },
  {
    id: 'GU',
    title: 'GU',
    country: 'US',
  },
  {
    id: 'MP',
    title: 'MP',
    country: 'US',
  },
  {
    id: 'PR',
    title: 'PR',
    country: 'US',
  },
  {
    id: 'UM',
    title: 'UM',
    country: 'US',
  },
  {
    id: 'VI',
    title: 'VI',
    country: 'US',
  },
];
