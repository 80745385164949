import React from 'react';
import PropTypes from 'prop-types';
import './SimplePropertyDisplay.scss';

const SimplePropertyDisplay = (props) => {
  const { label, text } = props;

  return (
      <div className={'simple-property-display__container'}>
        <div className={'simple-property-display__item simple-property-display__label'}>{label}:</div>
        <div className={'simple-property-display__item overflow-ellipsis'}>{text || ''}</div>
      </div>
  );
};

SimplePropertyDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.any,
};

export default SimplePropertyDisplay;
