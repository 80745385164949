import './FormSelect.scss';
import { Form } from 'react-bootstrap';
import errorIcon from '../../../theme/assets/awesome-exclamation-circle.svg';
import PropTypes from 'prop-types';

const FormSelect = ({ error, id, label, options, ...props }) => (
  <Form.Group controlId={id} className="FormSelect__form-group">
    <Form.Label className="FormSelect__form-label">{label}</Form.Label>
    <Form.Control
      className="FormSelect__select"
      as="select"
      isInvalid={error}
      {...props}
    >
      <option></option>
      {options.map((option, index) => (
        <option key={`${option.title}${index}`} value={option.id}>
          {option.title}
        </option>
      ))}
    </Form.Control>
    {error && (
      <span className="input-error" id={`${id}Error`}>
        <img src={errorIcon} alt="errorIcon" style={{ padding: '0px 5px' }} />
        {error}
      </span>
    )}
  </Form.Group>
);

FormSelect.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FormSelect;
