import { Auth } from 'aws-amplify';

export const signup = async (user) => {
  try {
    const newUser = await Auth.signUp(user);
    return newUser;
  } catch (error) {
    return error;
  }
};

export const confirmSignup = async (username, code) => {
  try {
    return await Auth.confirmSignUp(username, code);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const resendCode = async (email) => {
  try {
    return await Auth.resendSignUp(email);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const login = async (email, password) => {
  try {
    const someToken = await Auth.signIn(email, password);
    return someToken;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (username) => {
  try {
    return await Auth.forgotPassword(username);
  } catch (err) {
    throw err;
  }
};

export const forgotPasswordSubmit = async (username, code, new_password) => {
  try {
    return await Auth.forgotPasswordSubmit(username, code, new_password);
  } catch (err) {
    throw err;
  }
};

export const updatePassword = async (oldPassword, newPassword) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  } catch (err) {
    throw err;
  }
};

export const newPassword = async (username, currentPassword, password) => {
  try {
    const user = await Auth.signIn(username, currentPassword);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const passwordRes = await Auth.completeNewPassword(
        user, // the Cognito User Object
        password // the new password
      );
      setSession(JSON.stringify(passwordRes));
      return passwordRes;
    }
  } catch (err) {
    throw err;
  }
};

export const updateProfile = async (attributes) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
  } catch (err) {
    throw err;
  }
};

export const setSession = (jwt, external, timeoutRedirectUrl = null) => {
  sessionStorage.setItem('access_token', jwt);
  sessionStorage.setItem('external_identity', external === true);
  if (timeoutRedirectUrl)
    sessionStorage.setItem('timeout_redirect_url', timeoutRedirectUrl);
};

export const logout = async () => {
  try {
    if (isDirectLogin()) {
      await Auth.signOut();
    }
    clear();
  } catch (err) {
    throw err;
  }
};

export const clear = () => {
  // Clear local storage when user sign out or session expires
  sessionStorage.clear();
  localStorage.clear();
};

export const getToken = () => {
  return JSON.parse(sessionStorage.getItem('access_token'));
};

export const isAuthenticated = () => {
  // TODO: Check whether the current time is past the Access Token's expiry time

  const token = getToken();
  const hasSignInUserSession = !!token && token.signInUserSession !== null;

  if (isDirectLogin()) {
    return hasSignInUserSession;
  }

  // If using external auth, make sure it completed
  let externalAuthComplete = JSON.parse(
    sessionStorage.getItem('external_auth_complete')
  );

  return hasSignInUserSession && externalAuthComplete;
};

export const isDirectLogin = () => {
  try {
    return !JSON.parse(sessionStorage.getItem('external_identity'));
  } catch (err) {
    return false;
  }
};

export const getRedirectUrl = () => {
  return sessionStorage.getItem('timeout_redirect_url');
};

export const getCurrentUserInfo = async () => {
  try {
    return await Auth.currentUserInfo();
  } catch (err) {
    return err;
  }
};

export const verifyAttribute = async (attr) => {
  try {
    return await Auth.verifyCurrentUserAttribute(attr);
  } catch (err) {
    throw err;
  }
};

export const verifyAttributeSubmit = async (attr, code) => {
  try {
    return await Auth.verifyCurrentUserAttributeSubmit(attr, code);
  } catch (err) {
    throw err;
  }
};
