import { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { SET_QUERY_PARAMS } from '../../redux/constants';
import { useDispatch } from 'react-redux';
import jwt from 'jsonwebtoken';

const Start = () => {
  const [stateCheck, setStateChecked] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    checkQueryParams();
  }, []);

  const checkQueryParams = () => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.payload) {
      try {
        const data = jwt.decode(queryParams.payload);
        dispatch({
          type: SET_QUERY_PARAMS,
          payload: data,
        });
      } catch (e) {
        console.log('Error while reading the jwt payload', e);
      }
    }
    setStateChecked(true);
  };
  return stateCheck ? <Redirect to="/auth/sign-up" /> : <></>;
};

export default Start;
