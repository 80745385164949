import axios from 'axios';
import {
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
} from '../constants';

export const getStateList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_STATES_REQUEST });
    await axios
      .get(`/registry`)
      .then((data) =>
        dispatch({ type: GET_STATES_SUCCESS, payload: { ...data } })
      );
  } catch (error) {
    if (error.response && error.response.data) {
      dispatch({
        type: GET_STATES_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: GET_STATES_FAILURE,
        payload: error.message,
      });
    }
  }
};
