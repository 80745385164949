import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { userReducer } from './reducers/user';
import { matchReducer } from './reducers/match';
import { stateSelectedReducer } from './reducers/stateSelected';
import { consentReducer } from './reducers/consent';
import { externalDataReducer } from './reducers/externalData';
import { phoneVerificationReducer } from './reducers/phoneVerification';
import { botcoReducer } from './reducers/botco';

const reducer = combineReducers({
  user: userReducer,
  consent: consentReducer,
  externalData: externalDataReducer,
  matchResult: matchReducer,
  stateSelected: stateSelectedReducer,
  phoneVerification: phoneVerificationReducer,
  botco: botcoReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
