import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../shared/components/Button';
import TextInput from '../../shared/components/TextInput';
import { validateData } from '../../shared/utilities/validation';
import Loading from '../../shared/components/Loading';
import { login, clearCognitoError, updateDemographicsEmail } from '../../../redux/actions/user';
import { SET_EMAIL } from '../../../redux/constants';
import '../../theme/styles/global.scss';
import './SignIn.scss';

const SignIn = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const {
    user: { demographics, auth, email },
    error: cognitoError,
    loading,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  let isFormValidated = !!(emailAddress && password &&
      Object.values(errors).every((x) => x === true || x === ''));

  useEffect(() => {
    if (email) {
      setEmailAddress(email);

      // Validate if the email address received in the url is valid
      let emailError = validateData('emailAddress', 'email', email);
      setErrors((preErrors) => ({ ...preErrors, ...emailError }));
    }
  }, [email]);

  useEffect(() => {
    if (cognitoError && cognitoError.message) {
      setErrors((prevState) => ({ ...prevState, ...{cognitoError: cognitoError.message} }));
    } else {
      setErrors((prevState) => ({ ...prevState, ...{cognitoError: ''} }));
    }
  }, [cognitoError]);

  useEffect(() => {
    if (auth) {
      if (auth.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // TODO: Handle this workflow
        history.push('/oauth/password-reset', { tempPassword: true, password });
      } else {
        // Need to check demographics.email & email to see if they changed. If they changed,
        // save the new email to user.demographics.
        if (email && demographics &&
          demographics.email && email !== demographics.email) {
          dispatch(updateDemographicsEmail(email));
        }

        // Redirect to the finding information component that will retrieve the user info from myIR
        history.push({
          pathname: '/oauth/finding',
          state: { prevPath: history.location.pathname },
        });
      }
    }
  }, [auth]);

  const handleFieldChange = (event, fieldType) => {
    const data = event.target;
    const trimmedValue = data.value.trim();

    if (data.id === 'emailAddress') {
      setEmailAddress(trimmedValue);
    } else if (data.id === 'password') {
      setPassword(trimmedValue);
    }

    const validationError = validateData(data.id, fieldType, trimmedValue, data.required);
    setErrors((prevState) => ({ ...prevState, ...validationError }));

    if (cognitoError && cognitoError.message) {
      dispatch(clearCognitoError());
    }

    for (let val in validationError) {
      if (validationError[val] === '')
        setErrors((prevState) => ({
          ...prevState,
          ...validationError,
          [val]: '',
        }));
    }
  };

  const handleSubmit = () => {
    if (isFormValidated) {
      dispatch(login(emailAddress.toLowerCase(), password));
      setPassword('');
    }
  };

  const handleRedirect = () => {
    dispatch(clearCognitoError());
    dispatch({
      type: SET_EMAIL,
      payload: emailAddress,
    });

    history.push({
      pathname: '/oauth/sign-up',
      state: { prevPath: history.location.pathname },
    });
  };

  const handleForgotPassword = () => {
    dispatch(clearCognitoError());
    dispatch({
      type: SET_EMAIL,
      payload: emailAddress,
    });

    history.push({
      pathname: '/oauth/password-reset',
      state: { prevPath: history.location.pathname },
    });
  };

  return (
    <div className="screen-container style-overrides">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <h4 className="layout-padding-large-bottom layout-padding-xlarge-top">
            You can sign in with your MyIR account to continue.
          </h4>
          <TextInput
            id={'emailAddress'}
            name={'emailAddress'}
            required
            value={emailAddress}
            label={'E-mail'}
            placeholder={'Email'}
            onChange={(e) => handleFieldChange(e, 'email')}
            onBlur={(e) => handleFieldChange(e, 'email')}
            error={errors.emailAddress}
          />
          <TextInput
            id={'password'}
            name={'password'}
            required
            value={password}
            label={'Password'}
            placeholder={'Password'}
            togglePw={true}
            onChange={(e) => handleFieldChange(e, 'notEmpty')}
            onBlur={(e) => handleFieldChange(e, 'notEmpty')}
            error={errors.password}
          />

          <div className={'layout-padding-small-bottom'}>
            <Button
                id={'signIn'}
                className={'Button Button--primary default__button-label default__button-label--primary'}
                onClick={handleSubmit}
                disabled={!isFormValidated}>
              Continue
            </Button>
          </div>

          {
            (errors['cognitoError'] && errors['cognitoError'] !== '') ?
              <div id={'signin-error'} className={'cta-error layout-padding-small-bottom'}>
                {errors['cognitoError']}
              </div>
              :
              null
          }

          <div className={'style-overrides layout-padding-small-bottom'}>
            <Button id={'forgot-password'}
                className={'Button Button--secondary default__button-label default__button-label--secondary'}
                onClick={handleForgotPassword}>
              Forgot password?
            </Button>
          </div>

          <div className="goToSignUp">
            <h5>Don´t have a MyIR account?</h5>
            <Button
              id={'sign-up-redirect'}
              type={'button'}
              className={'Button default__button-label Button__border-primary font-style-normal'}
                onClick={handleRedirect}>
              Sign Up
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
