import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import './VaccineConfirmationForm.scss';
import FormSelect from '../../shared/components/FormSelect';
import { SET_STATE_SELECTED } from '../../../redux/constants';
import Button from '../../shared/components/Button';
import { getStateList } from '../../../redux/actions/externalData';
import { transformLoadedStatesToUI } from '../../shared/utilities/transforms';
import { setContainerHeight } from '../../shared/utilities/effects';

const VaccineConfirmationForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const targetRef = useRef();
  const [screenHeight, setScreenHeight] = useState(0);
  const [vaccineLocation, setVaccineLocation] = useState({
    id: '',
    title: '',
    stateCode: '',
  });
  const [error, setError] = useState({});
  const { states } = useSelector((state) => state.externalData);
  const loadedStates = transformLoadedStatesToUI(states);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setScreenHeight(
        setContainerHeight({
          useHeader: true,
          useFooter: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getStateList());
  }, []);

  const handleUpdateVaccineLocation = (location) => {
    if (!location) {
      setVaccineLocation({ id: '', title: '', stateCode: '' });
    } else {
      let selectedLocation = states.find((state) => state.id === location);
      setVaccineLocation(selectedLocation);
    }
    checkForValidData(location);
  };

  const checkForValidData = (data) => {
    let newState = {};
    newState['state'] = !data ? 'A valid state is required to continue.' : '';
    setError(newState);
  };

  const continueDisabled = () => {
    return !!!vaccineLocation.id;
  };

  const findVaccineData = () => {
    dispatch({
      type: SET_STATE_SELECTED,
      payload: vaccineLocation,
    });
    history.push({
      pathname: '/oauth/finding',
      state: {
        prevPath: '/oauth/vaccine-confirmation-form',
        vaccineLocation: vaccineLocation,
      },
    });
  };

  return (
    <div
      className={
        'screen-container vaccine-confirmation-form__wrapper style-overrides'
      }
      ref={targetRef}
      style={{ height: screenHeight + 'px' }}
    >
      <div
        className={'flex-container flex-column flex-align-stretch flex-nowrap'}
      >
        <h1 className={'layout-padding-xlarge-top'}>
          Your COVID-19 vaccination
        </h1>

        <h4 className={'layout-padding-large-top'}>
          Where did you get{' '}
          <span className={'font-weight-override-700'}>
            your COVID-19 vaccination
          </span>
          ?
        </h4>

        {loadedStates ? (
          <div className={'layout-padding-large-top'}>
            <FormSelect
              id={'vaccine-location'}
              label={'Select a state'}
              options={loadedStates}
              onChange={(e) => {
                handleUpdateVaccineLocation(e.target.value);
              }}
              error={error && error.state}
              value={vaccineLocation ? vaccineLocation.title : ''}
            />
          </div>
        ) : null}
      </div>

      <div className={'flex-container flex-column flex-align-stretch flex-nowrap layout-padding-medium-bottom'}>
        <Button
          id={'next'}
          className={'Button Button--primary default__button-label default__button-label--primary'}
          onClick={() => findVaccineData()}
          disabled={continueDisabled()}
        >
          Next
        </Button>

        <div
          className={'layout-padding-medium-top layout-padding-large-bottom'}
        >
          <Link
            to={{
              pathname: '/oauth/no-vaccine',
              state: { prevPath: history.location.pathname },
            }}
          >
            <Button
              id={'no-vaccine'}
              className={'Button Button--secondary default__button-label default__button-label--secondary'}
            >
              I haven't gotten my vaccination yet.
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VaccineConfirmationForm;
