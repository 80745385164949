import { ReactComponent as Info } from '../../../theme/assets/Info.svg';
import './Alert.scss';

const Alert = (props) => {
  const { message, color } = props;
  return (
    <div className={`alert-message ${color}-message`}>
      <Info className="info-icon" />
      <p>{message}</p>
    </div>
  );
};

export default Alert;
