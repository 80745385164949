import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

const ProtectedRoute = (props) => {
  const auth = useSelector((state) => state.user.user.auth);
  if (auth) {
    return <Route {...props} />;
  } else {
    return (
      <Route>
        <Redirect to="/oauth/sign-up" />
      </Route>
    );
  }
};

export default ProtectedRoute;
