/**
 * Available options for `params.referralCodes`.
 *
 * Used by `BotcoWebChat.mount(params)`
 */
export const BOTCO_REFERRAL_CODES = Object.freeze({
  /** Generic help */
  default: null,

  /** Future referral codes for Consumer Access Network */
});
