import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Button';
import PropTypes from 'prop-types';

import './StyledModal.scss';
class StyledModal extends Component {
  state = {
    disabled: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show === false) {
      this.state.disabled && this.setState({ disabled: false });
    }
  }

  render() {
    const {
      confirmButtonLabel = 'Continue',
      leaveText,
      title,
      show,
      onClose,
      onConfirm,
      showCancel,
      showConfirm,
      cancelButtonLabel = 'Back',
      onHide,
      ...modalProps
    } = this.props;
    const leaveMessage =
      leaveText ||
      'You have unsaved changes, are you sure you wish to continue?';
    if (!show) {
      return null;
    }

    return (
      <div>
        <Modal
          className={'modal-container'}
          show={show}
          onHide={onHide}
          {...modalProps}
        >
          <Modal.Header>
            <Modal.Title className={'modal-title'}>
              <h2 className={'modal-title__title font-style-normal'}>{title}</h2>
              <span
                className={'span__modal-close-X'}
                onClick={() => {
                  this.setState({ disabled: true });
                  onClose();
                }}>
                &times;
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={'modal-body'}>
            <div className={'flex-container flex-column flex-justify-center flex-align-center flex-fullsize flex-nowrap'}>
              <h3 className={'font-weight-override-300'}>
                {leaveMessage}
              </h3>
            </div>
          </Modal.Body>
          <Modal.Footer className={'model-footer'}>
            {showConfirm && (
              <Button
                id={'forgot-ps'}
                className={'Button Button--primary default__button-label default__button-label--primary'}
                onClick={() => {
                  this.setState({ disabled: true });
                  onConfirm();
                }}
                disabled={this.state.disabled}
              >
                {confirmButtonLabel}
              </Button>
            )}
            {showCancel ? (
              <Button
                id={'forgot-ps'}
                className={'Button default__button-label no-border-button'}
                onClick={() => {
                  this.setState({ disabled: true });
                  onClose();
                }}
                disabled={this.state.disabled}
              >
                {cancelButtonLabel}
              </Button>
            ) : null}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

StyledModal.defaultProps = {
  checked: false,
  showCancel: false,
  showConfirm: true,
  onHide: () => {},
};

StyledModal.propTypes = {
  showCancel: PropTypes.bool.isRequired,
  showConfirm: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default StyledModal;
