import { SET_STATE_SELECTED } from '../constants';

const initialState = {
  stateSelected: '',
};

export const stateSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATE_SELECTED:
      return action.payload;
    default:
      return state;
  }
};
