import { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Alert from '../shared/components/Alert';
import Button from '../shared/components/Button';
import { setContainerHeight } from '../shared/utilities/effects';
import { clientRedirectUriErrorParams } from '../shared/utilities/errorMessages';
import { errorMessages } from '../shared/utilities/errorMessages';
import '../theme/styles/global.scss';

const ErrorScreen = () => {
  const targetRef = useRef();
  const client = useSelector((state) => state.user.client);
  const [screenHeight, setScreenHeight] = useState(0);

  useLayoutEffect(() => {
    if (targetRef.current) {
      let options = {
        manualHeightOffset: 70,
        useHeader: true,
        useFooter: true,
      };
      setScreenHeight(setContainerHeight(options));
    }
  }, []);

  const routeToExternalApp = () => {
    const stateParameter = client.state ? '&state=' + client.state : '';
    const externalAppUrl = `${client.redirect_uri}${clientRedirectUriErrorParams}${stateParameter}`;
    window.location.href = externalAppUrl;
  };

  return (
    <div
      className={'dashboard-container'}
      ref={targetRef}
      style={{ height: screenHeight + 'px' }}
    >
      <div className={
        'flex-container flex-column flex-align-center ' +
        'flex-fullsize flex-nowrap flex-justify-space-between'
        }>
        <div className={'records-container'}>
          <Alert
            message={errorMessages.genericAppErrorMessage}
            color={'red'} />
        </div>

        <div>
          <Button
            id={'btnReturnToApp'}
            className={'Button default__button-label'}
            onClick={() => routeToExternalApp()}
          >
            Return to {
              client ? client.app_name : 'App'
            }
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ErrorScreen);
