import PropTypes from 'prop-types';

import './CheckBox.scss';
const Checkbox = (props) => {
  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        name={props.id}
        value={props.value}
        id={props.id}
        checked={props.checked}
        onChange={props.onChange}
      />
      <label htmlFor={props.id}>
        <span className="label-text">{props.label}</span>
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  checked: false,
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default Checkbox;
