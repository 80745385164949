import React from 'react';
import TextInput from '../../shared/components/TextInput/index';
import FormSelect from '../../shared/components/FormSelect';
import { states } from '../constants/States';

const Address = ({ address, onChange, onBlur, toggleItem, error }) => {
  const selected = address.state && states.find((state) => state.id === address.state);

  return (
    <>
      <TextInput
        id="address"
        placeholder=""
        label="Street"
        type="text"
        value={address.address}
        onChange={onChange}
        error={error && error.address && error.address}
        onBlur={onBlur}
      />

      <TextInput
        id="address2"
        placeholder=""
        type="text"
        label="Street (optional)"
        value={address.address2}
        onChange={onChange}
        onBlur={onBlur}
      />

      <TextInput
        id="city"
        placeholder=""
        label="City"
        type="text"
        value={address.city}
        onChange={onChange}
        error={error && error.city && error.city}
        onBlur={onBlur}
      />
      <div className="postal-code-state-name-row">
        <TextInput
          id="zipCode"
          placeholder=""
          label="Zip code"
          value={address.zipCode}
          onChange={onChange}
          error={error && error.zipCode && error.zipCode}
          onBlur={onBlur}
          className={'postal-code'}
        />

        <FormSelect
          id="state"
          label="State"
          options={states}
          onChange={(e) => {
            toggleItem(e.target.value);
          }}
          error={error && error.state}
          value={selected ? selected.id : undefined}
        />
      </div>

      <TextInput
        id="country"
        label="Country"
        value={address.country}
        onChange={onChange}
        placeholder="United States"
        disabled
        onBlur={onBlur}
      />
    </>
  );
};

export default Address;
