import '../../theme/styles/global.scss';

const PasswordStrength = ({ passwordStrength }) => {
  const types = ['uppercase', 'number', 'characters'];

  return (
    <div className="passwordStrength-container layout-padding-xlarge-bottom">
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {types.map((type) => (
          <div
            key={type}
            className={`password-strength__bar ${
              // strengthLevel === 3 || (strengthLevel && i + 1 <= strengthLevel)
              passwordStrength[type] ? 'password-strength__bar--active' : ''
            }`}
          />
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {types.map((type, i) => (
          <div key={`${type}-check`} className="password-strength__indicator">
            <span
              className={`password-strength__check__container ${
                passwordStrength[type]
                  ? 'password-strength__check__container--active'
                  : ''
              }`}
            >
              <span
                className={`password-strength__check ${
                  passwordStrength[type]
                    ? 'password-strength__check--active'
                    : ''
                }`}
              />
            </span>
            <span className="password-strength__text">
              {i === 0 ? 'Uppercase' : i === 1 ? 'Number' : 'Min. 8 characters'}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PasswordStrength;
