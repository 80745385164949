import {
  GET_MATCH_REQUEST,
  GET_MATCH_FAILURE,
  GET_MATCH_SUCCESS,
  RESET_MATCH_LOADED,
} from '../constants';

const initialState = {
  loaded: false,
  loading: false,
  error: false,
};

export const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MATCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_MATCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case GET_MATCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload,
      };
    case RESET_MATCH_LOADED:
      return {
        loaded: false,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};
