import { useRef, useState, useLayoutEffect, useEffect } from 'react';
import TextInput from '../../shared/components/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getHistory } from '../../../redux/actions/user';

import Button from '../../shared/components/Button/index';
import { phoneFormatter } from '../../shared/utilities/regex';
import { setContainerHeight } from '../../shared/utilities/effects';
import {
  getVerificationCode,
  confirmVerificationCode,
} from '../../../redux/actions/phoneVerification';
import '../../theme/styles/global.scss';
import './VerifyCode.scss';

export default function VerifyCode() {
  const targetRef = useRef();
  const dispatch = useDispatch();
  const routerHistory = useHistory();
  const [screenHeight, setScreenHeight] = useState(0);
  const [verificationCode, setVerificationCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [resendCodeText, setResendCodeText] = useState('Resend');
  const { phoneVerification } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if(user.history) {
      setCodeError('');
      getTwilioCode();
    }
  }, [user.history]);

  useEffect(() => {
    if (phoneVerification.confirmVerificationCodeLoaded === true) {
      console.log('code is valid');
      routerHistory.push('/oauth/consent');
    }
    if (phoneVerification.invalid === true) {
      console.log('code is invalid');
      setCodeError('The code you entered was not valid. Please try again.');
    }
  }, [phoneVerification]);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setScreenHeight(
        setContainerHeight({
          manualHeightOffset: 40,
          useHeader: true,
          useFooter: true,
        })
      );
    }
  }, []);

  const getTwilioCode = () => {
    setCodeError('');
    if (user.demographics && user.history) {
      const correctPhone =
        user.demographics.phoneNumber.substring(3, 6) +
        user.demographics.phoneNumber.substring(6);

      const correctPhoneObject = user.history.person.phoneNumbers.find(
        (phone) => {
          return (
            phone.phoneNumber.trim().replaceAll('-', '').replaceAll(' ', '') ===
            correctPhone.trim().replaceAll('-', '').replaceAll(' ', '')
          );
        }
      );

      const phoneData = {
        countryCode: correctPhoneObject.countryCode,
        areaCode: correctPhoneObject.areaCode,
        phoneNumber: correctPhoneObject.phoneNumber,
        phoneType: correctPhoneObject.phoneType,
        phoneId: correctPhoneObject.id,
        personId: user.history.person.personId,
        userId: user.history.person.userId,
      };

      dispatch(getVerificationCode(phoneData));
    }
  };

  const confirmTwilioCode = async () => {
    setCodeError('');
    const correctPhone =
      user.demographics.phoneNumber.substring(3, 6) +
      '-' +
      user.demographics.phoneNumber.substring(6);

    const correctPhoneObject = user.history.person.phoneNumbers.find(
      (phone) => {
        return (
          phone.phoneNumber.trim().replaceAll('-', '').replaceAll(' ', '') ===
          correctPhone.trim().replaceAll('-', '').replaceAll(' ', '')
        );
      }
    );
    const confirmationData = {
      personId: user.history.person.personId || user.personId,
      userId: user.history.person.userId || user.userId,
      verificationSid: phoneVerification.sid,
      code: verificationCode,
      phone: {
        id: phoneVerification.phoneId,
        countryCode: correctPhoneObject.countryCode,
        areaCode: correctPhoneObject.areaCode,
        phoneNumber: correctPhoneObject.phoneNumber,
        phoneType: correctPhoneObject.phoneType,
      },
    };
    await dispatch(confirmVerificationCode(confirmationData));
  };

  const handleResendVerification = () => {
    setResendLoading(true);
    setResendCodeText('A new code has been sent!');
    setCodeError(codeError);
    getTwilioCode();
  };

  const onChange = (e) => {
    let codeErr = e.target.value.length === 6 ? '' : codeError;
    if (e.target.value.length <= 6) {
      setVerificationCode(e.target.value.replace(/[^0-9]/g, ''));
      setCodeError(codeErr);
    }
  };

  const validateInput = (e) => {
    if (e.target.value.length < 6) setCodeError('Invalid code');
  };

  return (
    <div
      className={'screen-container verify-code__flex-container style-overrides'}
      ref={targetRef}
      style={{ height: screenHeight + 'px' }}
    >
      <div className={'layout-padding-medium-top'}>
        <h1 className={'layout-padding-xlarge-top'}>Almost there!</h1>
      </div>

      <div>
        <h5 className={'layout-padding-medium-top'}>
          We've sent a verification code to{' '}
        </h5>
        <h5 className={'font-weight-override-700 layout-padding-small-top'}>
          {user.demographics && phoneFormatter(user.demographics.phoneNumber)}
        </h5>

        <div className={'layout-padding-large-top'}>
          <TextInput
            label="Enter your 6-digit code:"
            id="verificationCode"
            type="text"
            value={verificationCode}
            onChange={(e) => onChange(e)}
            placeholder=""
            error={codeError}
            required
            pattern="[0-9]*"
            handleEnter={confirmTwilioCode}
            onBlur={validateInput}
          />
        </div>

        <div className={'layout-padding-large-top'}>
          <Button
            className={
              'Button Button--primary default__button-label default__button-label--primary'
            }
            onClick={confirmTwilioCode}
            id="submitCode"
            disabled={
              verificationCode.length !== 6 ||
              (phoneVerification &&
                phoneVerification.confirmVerificationCodeLoading)
            }
          >
            {phoneVerification &&
            phoneVerification.confirmVerificationCodeLoading &&
            verificationCode.length === 6
              ? 'Confirming...'
              : 'Continue'}
          </Button>

          <div className={'layout-padding-xlarge-top'}>
            <h5 className="layout-padding-small-top">Didn't get the code?</h5>
            <Button
              id={'resendCode'}
              className={'Button default__button-label Button__border-primary'}
              onClick={handleResendVerification}
            >
              {phoneVerification.loading && resendLoading
                ? 'Sending...'
                : resendCodeText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
