import {
  GET_VERIFICATION_CODE_REQUEST,
  GET_VERIFICATION_CODE_SUCCESS,
  GET_VERIFICATION_CODE_FAILURE,
  CONFIRM_VERIFICATION_CODE_REQUEST,
  CONFIRM_VERIFICATION_CODE_SUCCESS,
  CONFIRM_VERIFICATION_CODE_FAILURE
} from '../constants';

const initialState = {
  loading: false,
  loaded: false,
  confirmVerificationCodeLoaded: false,
  confirmVerificationCodeLoading: false
};

export const phoneVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        invalid: false,
      };
    case GET_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        loaded: true,
        invalid: false,
      };
    case GET_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case CONFIRM_VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        confirmVerificationCodeLoading: true,
        invalid: false,
      };
    case CONFIRM_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        confirmVerificationCodeLoading: false,
        confirmVerificationCodeLoaded: true,
        invalid: false,
      };
    case CONFIRM_VERIFICATION_CODE_FAILURE:
      const error = action.error ? action.error : action.payload;
      return {
        ...state,
        confirmVerificationCodeLoading: false,
        confirmVerificationCodeLoaded: false,
        error: error,
        invalid: true,
      };
    default:
      return state;
  }
};
