import axios from 'axios';
import { login as cognitoLogin } from '../../modules/auth/services/Auth';
import { signup as cognitoSignUp } from '../../modules/auth/services/Auth';
import { forgotPassword as cognitoPWResetVerificationCode } from '../../modules/auth/services/Auth';
import { forgotPasswordSubmit as cognitoResetPassword } from '../../modules/auth/services/Auth';
import {
  GET_HISTORY_FAILURE,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_DEMOGRAPHICS_FAILURE,
  UPDATE_USER_DEMOGRAPHICS_SUCCESS,
  CLEAR_COGNITO_ERROR,
  GET_PW_RESET_VERIFICATION_CODE_REQUEST,
  GET_PW_RESET_VERIFICATION_CODE_SUCCESS,
  GET_PW_RESET_VERIFICATION_CODE_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_RESET_STEP,
  UPDATE_DEMOGRAPHICS_EMAIL,
} from '../constants';

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_USER_REQUEST });

    const user = await cognitoLogin(username, password);
    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: user,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_USER_FAILURE,
      payload: error,
    });
  }
};

export const getHistory = (cognitoId) => async (dispatch) => {
  try {
    dispatch({ type: GET_HISTORY_REQUEST });
    const { data } = await axios.get(`/account/history?cognitoId=${cognitoId}`);
    dispatch({ type: GET_HISTORY_SUCCESS, payload: { ...data } });
  } catch (error) {
    console.log('error while fetching getHistory');
    if (error.response && error.response.data) {
      dispatch({
        type: GET_HISTORY_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: GET_HISTORY_FAILURE,
        payload: error.message,
      });
    }
  }
};

export const signupUser =
  (demographics, email, password) => async (dispatch) => {
    dispatch({ type: CREATE_USER_REQUEST });

    const user = {
      firstName: demographics.firstName,
      lastName: demographics.lastName,
      dob: demographics.dob,
      phoneNumber: demographics.phoneNumber,
      email: email,
      phoneType: demographics.phoneType,
      gender: demographics.gender,
      address: {
        address: demographics.address?.address,
        address2: demographics.address?.address2,
        city: demographics.address?.city,
        state: demographics.address?.state,
        country: demographics.address?.country,
        zipCode: demographics.address?.zipCode,
      },
    };

    const cognitoUser = await cognitoSignUp({
      username: email.toLowerCase(),
      password: password,
      attributes: {
        email: email.toLowerCase(),
      },
    });

    if (cognitoUser.user) {
      const userConfirmed = await axios.post('/account/confirm', {
        username: email.toLowerCase(),
      });

      if (userConfirmed.status === 200) {
        const userMyIR = {
          user: user,
          cognitoId: cognitoUser.userSub,
        };
        const userCreatedResponse = await axios.post('/account', userMyIR);

        if (userCreatedResponse.status === 200) {
          dispatch({
            type: CREATE_USER_SUCCESS,
            payload: {
              userId: userCreatedResponse.data.userId,
              personId: userCreatedResponse.data.personId,
              cognitoId: userMyIR.cognitoId,
            },
          });
        } else {
          console.log(
            'Error: Cant create the user on myir. Detail:',
            userCreatedResponse
          );
          dispatch({
            type: CREATE_USER_FAILURE,
            payload: userCreatedResponse,
          });
        }
      } else {
        console.log("Can't confirm the user in Cognito: ", userConfirmed);
        let dispatchObject = {
          type: CREATE_USER_FAILURE,
          payload: userConfirmed,
        };
        dispatch(dispatchObject);
      }
    } else {
      console.log("Can't create the user in Cognito: ", cognitoUser);
      let dispatchObject = {
        type: CREATE_USER_FAILURE,
        payload: cognitoUser,
      };
      dispatch(dispatchObject);
    }
  };

export const updateUser = (person, email) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const requestBody = {
      firstName: person.firstName,
      lastName: person.lastName,
      dateOfBirth: person.dateOfBirth,
      consentToRegistry: true,
      emailAddress: email,
      gender: person.gender,
      primaryPerson: true,
      addressId: person.addresses.id,
      address: person.addresses.address,
      address2: person.addresses.address2,
      city: person.addresses.city,
      state: person.addresses.state,
      country: person.addresses.country,
      postalCode: person.addresses.postalCode,
      insertedBy: person.userId,
      personId: person.personId,
      userId: person.userId,
      phoneNumbers: [
        {
          id: person.phoneNumber.id ? person.phoneNumber.id : null,
          countryCode: person.phoneNumber.countryCode
            ? person.phoneNumber.countryCode
            : '+1',
          areaCode: person.phoneNumber.areaCode
            ? person.phoneNumber.areaCode
            : person.phoneNumber.phoneNumber.substring(0, 3),
          phoneNumber: person.phoneNumber.phoneNumber.substring(3),
          phoneType: person.phoneNumber.phoneType
            ? person.phoneNumber.phoneType.toUpperCase()
            : 'MOBILE',
          verified: false,
          number: person.phoneNumber.phoneNumber,
          key: person.phoneNumber.key ? person.phoneNumber.key : null,
        },
      ],
    };
    const response = await axios.put(
      `/account/${requestBody.personId}`,
      requestBody
    );

    if (response.status === 200) {
      dispatch({
        type: UPDATE_USER_SUCCESS,
      });
    } else {
      console.log(
        'Error: Cant update the user information in MyIR. Detail:',
        response
      );
      dispatch({
        type: UPDATE_USER_FAILURE,
      });
    }
  } catch (error) {
    console.log(
      'An error occurred while updating the user information. Detail:',
      error
    );
    if (error.response && error.response.data) {
      dispatch({
        type: UPDATE_USER_FAILURE,
      });
    }
  }
};

export const updateUserDemographics = (userDemographics, requiresVerification) => (dispatch) => {
  try {
    const data = {
      userDemographics: userDemographics,
      requiresVerification: requiresVerification
    }
    dispatch({ 
      type: UPDATE_USER_DEMOGRAPHICS_SUCCESS, 
      payload: { data }
    });
  } catch (error) {
    console.log('error while updating user demographics');
    if (error.response && error.response.data) {
      dispatch({
        type: UPDATE_USER_DEMOGRAPHICS_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: UPDATE_USER_DEMOGRAPHICS_FAILURE,
        payload: error.message,
      });
    }
  }
};

export const clearCognitoError = () => async (dispatch) => {
  dispatch({
    type: CLEAR_COGNITO_ERROR,
    payload: false,
  });
};

export const getPWResetVerificationCode = (email) => async (dispatch) => {
  dispatch({ type: GET_PW_RESET_VERIFICATION_CODE_REQUEST });

  try {
    const response = await cognitoPWResetVerificationCode(email);
    dispatch({
      type: GET_PW_RESET_VERIFICATION_CODE_SUCCESS,
      payload: response.CodeDeliveryDetails,
    });
  } catch (error) {
    if (error.message) {
      dispatch({
        type: GET_PW_RESET_VERIFICATION_CODE_FAILURE,
        payload: error,
      });
    } else {
      dispatch({
        type: GET_PW_RESET_VERIFICATION_CODE_FAILURE,
        payload:
          'Failed to retrieve verification code. Please try again later.',
      });
    }
  }
};

export const resetPassword =
  (email, verificationCode, newPassword) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    try {
      const response = await cognitoResetPassword(
        email,
        verificationCode,
        newPassword
      );
      dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (error) {
      if (error.message) {
        dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: error,
        });
      } else {
        dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: 'Failed to reset password. Please try again later.',
        });
      }
    }
  };

export const resetPasswordResetStep = () => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_RESET_STEP });
};

export const updateDemographicsEmail = (email) => (dispatch) => {
  dispatch({
    type: UPDATE_DEMOGRAPHICS_EMAIL,
    payload: email,
  });
};
