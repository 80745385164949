import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Loading from '../../shared/components/Loading';
import {
  getHistory,
  updateUserDemographics,
} from '../../../redux/actions/user';
import { getMatch } from '../../../redux/actions/match';
import { CLEANUP_LOGIN_FLOW } from '../../../redux/constants';
import '../../theme/styles/global.scss';
import './Finding.scss';

const Finding = () => {
  const dispatch = useDispatch();
  const routerHistory = useHistory();
  const location = useLocation();
  const {
    user: {
      auth,
      history,
      demographics,
      comingFromLoginFlow,
      comingFromCreateUserFlow,
      requiresVerification,
    },
    error: errorRequest,
  } = useSelector((state) => state.user);

  const stateSelected = useSelector((state) => state.stateSelected);

  const matchResult = useSelector((state) => state.matchResult);

  useEffect(() => {
    // If the user is coming from Select a state screen, dispatch getMatch, else dispatch getHistory
    if (
      (location.state &&
        location.state.prevPath &&
        location.state.prevPath === '/oauth/vaccine-confirmation-form') ||
      (location.state &&
        location.state.prevPath &&
        location.state.prevPath === '/oauth/profile')
    ) {
      const dob = demographics ? demographics.dob : history.person.dateOfBirth;
      const numberObject = demographics
        ? demographics.phoneNumber
        : history.person.phoneNumbers[0];
      const phoneNumber = demographics
        ? demographics.phoneNumber
        : numberObject.areaCode + '-' + numberObject.phoneNumber;
      const phoneType = demographics
        ? demographics.phoneType
        : numberObject.phoneType;

      dispatch(
        getMatch(
          auth.attributes.sub,
          stateSelected.registryStateCode,
          dob,
          comingFromCreateUserFlow ? null : phoneNumber,
          phoneType || null
        )
      );
    } else {
      dispatch(getHistory(auth.attributes.sub));
    }
  }, []);

  useEffect(() => {
    if (matchResult.loaded) {
      if (matchResult.error) {
        routerHistory.push('/oauth/error');
      } else if (matchResult.matchStatus === 'exact' && matchResult.found) {
        // If found === true it means that the user has covid vaccines, must be redirected to the consent screen
        let verify;
        if (history?.person?.phoneNumbers) {
          const phonesToVerify = history.person.phoneNumbers.filter(
            (phone) => phone.verified === false
          ).length;
          verify = phonesToVerify > 0 ? true : false;
        } else {
          verify = requiresVerification;
        }
        // If the user updated his information, we should move to the verify phone screen
        if (verify) {
          dispatch(getHistory(auth.attributes.sub));
          routerHistory.push('/oauth/verify-code');
        } else {
          routerHistory.push('/oauth/consent');
        }
      } else if (matchResult.matchStatus === 'exact' && !matchResult.found) {
        // Found = false means that there was no vaccine found, redirect to the record-not-found screen
        routerHistory.push('/oauth/record-not-found');
      } else if (matchResult.matchStatus === 'possible') {
        // If the user is authenticated, we retrieve its record from the database so we don't insert new info without need
        dispatch(getHistory(auth.attributes.sub));
        // Returns possible if there was an information mismatch and we should give the user a chance to update his information
        routerHistory.push('/oauth/record-not-matching');
      } else {
        // If status is possible and matchResult.found is false, then we should redirect to the no record found screen
        // If status is not found, then we should redirect to the no record found screen
        routerHistory.push('/oauth/record-not-found');
      }
    }
  }, [matchResult]);

  useEffect(() => {
    if (history && comingFromLoginFlow) {
      // The user is not even connected in the registry, we must redirect him to the flow as a new user
      if (!history.registryConnected) {
        dispatch({ type: CLEANUP_LOGIN_FLOW });
        redirectToConfirmDataMatch();
      } else if (!history.hasCovidVaccine) {
        // If the user does not have a covid vaccine, should be redirected to the record not found screen
        routerHistory.push('/oauth/record-not-found');
      } else if (history.hasCovidVaccine) {
        if (history.exactMatch) {
          // It's an exact match, should go to the consent screen

          // If the user updated his information, we should move to the verify phone screen
          if (requiresVerification) {
            routerHistory.push('/oauth/verify-code');
          } else {
            // Check if the user requires his phone to be verified taking the value coming from the backend
            if (history.requiresPhoneVerification) {
              const userDemographics = {
                ...demographics,
                phoneNumber: history.requiresPhoneVerification,
              };
              dispatch(updateUserDemographics(userDemographics));
              routerHistory.push('/oauth/verify-code');
            } else {
              routerHistory.push('/oauth/consent');
            }
          }
        } else {
          // Not an exact match, should be given the opportunity to update his information
          routerHistory.push('/oauth/record-not-matching');
        }
      }
    }
  }, [history]);

  useEffect(() => {
    if (errorRequest) {
      routerHistory.push({
        pathname: '/oauth/error',
        state: { prevPath: '/oauth/finding' },
      });
    }
  }, [errorRequest]);

  const redirectToConfirmDataMatch = () => {
    routerHistory.push({
      pathname: '/oauth/confirm-data-match',
      state: { prevPath: '/oauth/finding', registryConnected: false },
    });
  };

  return (
    <div className={'screen-container style-overrides'}>
      <h1 className={'font-weight-override-300 layout-padding-super-top'}>
        Finding your vaccine information...
      </h1>
      <Loading />
    </div>
  );
};

export default Finding;
