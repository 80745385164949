import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { setContainerHeight } from '../../shared/utilities/effects';
import { clientRedirectUriErrorParams } from '../../shared/utilities/errorMessages';
import Button from '../../shared/components/Button/index';
import '../../theme/styles/global.scss';
import '../../shared/components/Button/Button.scss';
import './FindRecords.scss';

const RecordNotMatching = () => {
  const routeHistory = useHistory();
  const targetRef = useRef();
  const [dataMatchMessage, setMessage] = useState("Your phone doesn't match.");
  const [screenHeight, setScreenHeight] = useState(0);
  const {
    client,
    user: { history },
    attempts,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (attempts > 0) {
      routeHistory.push('/oauth/record-not-found');
    }
    if (history && !history.found) {
      setMessage(`Your phone doesn't match.`);
    }
  }, []);

  useLayoutEffect(() => {
    if (targetRef.current) {
      let options = {
        manualHeightOffset: 70,
        useHeader: true,
        useFooter: true,
      };
      setScreenHeight(setContainerHeight(options));
    }
  }, []);

  const routeToExternalApp = () => {
    const stateParameter = client.state ? '&state=' + client.state : '';
    const externalAppUrl = `${client.redirect_uri}${clientRedirectUriErrorParams}${stateParameter}`;
    window.location.href = externalAppUrl;
  };

  const handleUpdateMyInfo = () => {
    routeHistory.push({
      pathname: '/oauth/profile',
      state: { prevPath: '/oauth/record-not-matching' },
    });
  };

  return (
    <div
      className={'screen-container records__flex-container style-overrides'}
      ref={targetRef}
      style={{ height: screenHeight + 'px' }}
    >
      <div>
        <h1 className={'layout-padding-xlarge-top'}>
          We couldn’t find your vaccine info.
        </h1>
        <h4 className={'layout-padding-large-top'}>
          {dataMatchMessage}
          <br />
          Update your information and try again.
        </h4>
      </div>

      <div className={'flex-container flex-column flex-align-stretch flex-nowrap'}>
        <Button
          id={'btnUpdateMyInfo'}
          className={'Button Button--primary default__button-label default__button-label--primary'}
          onClick={() => handleUpdateMyInfo()}
        >
          Update my information
        </Button>

        <div className={'layout-padding-medium-top'}>
          <Button
            id={'btnReturnToApp'}
            className={'Button default__button-label Button__border-primary'}
            onClick={() => routeToExternalApp()}
            >
            Return to {
              client ? client.app_name : 'App'
            }
          </Button>
        </div>
      </div>
      
    </div>
  );
};

export default withRouter(RecordNotMatching);
