import './PageHeader.scss';

const PageHeader = () => {
  return (
    <div className={'page-header layout-padding-large-top flex-container flex-row flex-center'}>
      <div className={'header-line'} />
      <div className={'header-content font-weight-override-400 flex-container flex-column flex-align-center'}>
        <span>Connecting to your</span>
        <span>Certified Health Record</span>
      </div>
      <div className={'header-line'} />
    </div>
  );
};

export default PageHeader;
