import axios from 'axios';
import {
  GET_MATCH_FAILURE,
  GET_MATCH_REQUEST,
  GET_MATCH_SUCCESS,
} from '../constants';

export const getMatch =
  (cognitoId, registryStateCode, dob, phoneNumber, phoneType) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_MATCH_REQUEST });
      const { data } = await axios.get(
        `/match?cognitoId=${cognitoId}&firstState=${registryStateCode}&dob=${dob}&phoneNumber=${phoneNumber}&phoneType=${phoneType}`
      );
      dispatch({ type: GET_MATCH_SUCCESS, payload: { ...data } });
    } catch (error) {
      console.log('Error while fetching getMatch');
      if (error.response && error.response.data) {
        dispatch({
          type: GET_MATCH_FAILURE,
          payload: error.response.data.message,
        });
      } else {
        dispatch({
          type: GET_MATCH_FAILURE,
          payload: error.message,
        });
      }
    }
  };
