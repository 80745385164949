import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Address from './Address';
import Gender from './Gender';
import {
  updateUserDemographics,
  updateUser,
} from '../../../redux/actions/user';
import TextInput from '../../shared/components/TextInput';
import Button from '../../shared/components/Button';
import {
  phoneFormatter,
  postalCodeFormatter,
} from '../../shared/utilities/regex';
import validations, { hasRequired, objectComparison } from '../../shared/utilities/validation';
import { errorMessages } from '../../shared/utilities/errorMessages';
import PhoneInput from '../../shared/components/PhoneInput/PhoneInput';
import {
  INCREMENT_MATCHING_ATTEMPT_SUCCESS,
  RESET_MATCH_LOADED,
  SET_COMING_FROM_CREATE_FALSE,
} from '../../../redux/constants';
import '../../theme/styles/global.scss';
import './Profile.scss';

const Profile = (props) => {
  const {
    user: { demographics, history, auth, userId, personId },
  } = useSelector(({ user }) => user);
  const matchResult = useSelector((state) => state.matchResult);
  const dispatch = useDispatch();
  const routerHistory = useHistory();

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    dob: '',
    phoneNumber: '',
    phoneType: 'MOBILE',
    address: {
      address: '',
      address2: '',
      city: '',
      zipCode: '',
      state: undefined,
      country: 'United States',
    }
  });
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState('');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (demographics && personId && userId) {
      setForm({
        userId,
        personId,
        firstName: demographics.firstName,
        lastName: demographics.lastName,
        dob: demographics.dob,
        phoneNumber: demographics.phoneNumber,
        phoneType: demographics.phoneType || 'MOBILE',
        gender: demographics.gender,
        address: {
          address: demographics.address?.address,
          address2: demographics.address?.address2,
          city: demographics.address?.city,
          country: demographics.address?.country,
          state: demographics.address?.state,
          zipCode: demographics.address?.zipCode,
        },
      });
    } else if (demographics) {
      setForm({
        firstName: demographics.firstName,
        lastName: demographics.lastName,
        dob: demographics.dob,
        phoneNumber: demographics.phoneNumber,
        phoneType: demographics.phoneType || 'MOBILE',
        gender: demographics.gender,
        address: {
          address: demographics.address?.address,
          address2: demographics.address?.address2,
          city: demographics.address?.city,
          country: demographics.address?.country,
          state: demographics.address?.state,
          zipCode: demographics.address?.zipCode,
        },
      });
    }
  }, [demographics]);

  useEffect(() => {
    if (history && history.person) {
      const phoneNumber =
        history.person.phoneNumbers && history.person.phoneNumbers.length === 1
          ? {
              phoneNumber: `(${history.person.phoneNumbers[0].areaCode})${history.person.phoneNumbers[0].phoneNumber}`,
              phoneType: history.person.phoneNumbers[0].phoneType,
              phoneId: history.person.phoneNumbers[0].id,
            }
          : {
              phoneNumber: demographics?.phoneNumber,
              phoneType: demographics?.phoneType || 'MOBILE',
              id: undefined,
            };
      setForm({
        userId: history.person.userId,
        personId: history.person.personId,
        firstName: history.person.firstName || demographics.firstName,
        lastName: history.person.lastName || demographics.lastName,
        dob: history.person.dateOfBirth || demographics?.dob,
        ...phoneNumber,
        gender: history.person?.gender,
        address: {
          addressId: history.person.addresses?.id,
          address: history.person.addresses?.address,
          address2: history.person.addresses?.address2,
          city: history.person.addresses?.city,
          country: history.person.addresses?.country || 'United States',
          state: history.person.addresses?.state,
          zipCode: history.person.addresses?.postalCode,
        },
      });
    }
  }, [history]);

  useEffect(() => {
    checkAllErrors();
  }, [errors]);

  const handleChange = (e) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
      unsavedChanges: true,
    }));
    let validationError = validations(e.target.id, e.target.value);
    for (let val in validationError) {
      if (validationError[val] === '') {
        setErrors((prevState) => ({
          ...prevState,
          [val]: '',
        }));
      } else {
        setToast(errorMessages.required);
      }
    }
  };

  const handleChangePhoneType = (phoneType) => {
    setForm((prevState) => ({
      ...prevState,
      phoneType,
      unsavedChanges: true,
    }));
  };

  const validateInput = (e) => {
    const validationError = validations(e.target.id, e.target.value);
    setErrors((prevState) => ({
      ...prevState,
      ...validationError,
    }));
  };

  const setGender = (value) => {
    setForm((prevState) => ({
      ...prevState,
      gender: value,
      unsavedChanges: true,
    }));
    setErrors((prevState) => ({
      ...prevState,
      gender: ''
    }));
  };

  const handleDOB = (e) => {
    const over18 = moment().diff(moment(e.target.value), 'years', true) > 18;
    setForm((prevState) => ({
      ...prevState,
      dob: e.target.value,
      unsavedChanges: true,
    }));
    setErrors((prevState) => ({
      ...prevState,
      dob:
        e.target.value && over18
          ? ''
          : e.target.value && !over18
          ? 'You must be 18 or older'
          : 'Required field.',
    }));
    if (!e.target.value || over18 === false) {
      setToast(errorMessages.required);
    }
  };

  const handleAddress = (e) => {
    let { value, id } = e.target;

    if (id === 'zipCode') {
      value = postalCodeFormatter(value);
    }
    setForm((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        [id]: value,
      },
      unsavedChanges: true,
    }));

    let validationError = validations(e.target.id, e.target.value);
    for (let val in validationError) {
      if (validationError[val] === '') {
        setErrors((prevState) => ({
          ...prevState,
          [val]: '',
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            ...validationError,
          },
        }));
        setToast(errorMessages.required);
      }
    }
  };

  const toggleDropdown = (state) => {
    setForm((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        state,
      },
    }));
  };

  const checkAllErrors = () => {
    let errorsArray = [];
    for (let err in errors) {
      errorsArray.push(errors[err]);
    }
    if (errorsArray.every((item) => item === '')) {
      setToast('');
    } else {
      setToast(errorMessages.required);
    }
  };
  
  const submitUserInfo = async (demographics) => {
    setSaving(true);
    const {
      userId,
      personId,
      firstName,
      lastName,
      dob,
      gender,
      phoneNumber,
      phoneId,
      phoneType,
      address,
    } = form;

    const validate = hasRequired(
      {
        phoneNumber,
        firstName,
        lastName,
        dob,
      },
      errors
    );

    let firstNameTrimmed = firstName.trim();
    let lastNameTrimmed = lastName.trim();

    setErrors(validate.errors);

    if (!validate.valid) {
      setToast(errorMessages.required);
      setSaving(false);
    } else {
      setToast('');
      const userDemographics = {
        firstName: firstNameTrimmed,
        lastName: lastNameTrimmed,
        dob: dob,
        phoneNumber: phoneNumber.replace(/[()-\s]/g, ''),
        phoneType,
        gender,
        address: {
          address: address.address,
          address2: address.address2,
          city: address.city,
          country: address.country || 'United States',
          state: address.state,
          zipCode: address.zipCode,
        },
      };
      
      const originalDemographics = {
        firstName: demographics?.firstName,
        lastName: demographics?.lastName,
        dob: demographics?.dob,
        phoneNumber: demographics?.phoneNumber?.replace(/[()-\s]/g, ''),
        phoneType: demographics?.phoneType || 'MOBILE',
        gender: demographics?.gender,
        address: {
          address: demographics?.address?.address,
          address2: demographics?.address?.address2,
          city: demographics?.address?.city,
          country: demographics?.address?.country || 'United States',
          state: demographics?.address?.state,
          zipCode: demographics?.address?.zipCode,
        },
      };

      const requiresVerification = 
        objectComparison(userDemographics, originalDemographics) ? false : true;
        
      const updateUserData = {
        userId,
        personId,
        firstName: firstNameTrimmed,
        lastName: lastNameTrimmed,
        dateOfBirth: dob,
        phoneNumber: {
          id: phoneId,
          phoneNumber: phoneNumber.replace(/[()-\s]/g, ''),
          phoneType,
        },
        gender,
        addresses: {
          ...address,
          id: address.addressId,
          postalCode: address.zipCode,
        },
      };
      try {
        // Check if there's something in history or matchResults, if there is, then it needs to be
        // updated in the db as well
        if (history || matchResult.loaded) {
          await dispatch(updateUserDemographics(userDemographics, requiresVerification));
          await dispatch(updateUser(updateUserData, auth.username));
          if (
            routerHistory.location.state.prevPath ===
            '/oauth/record-not-matching'
          ) {
            dispatch({ type: INCREMENT_MATCHING_ATTEMPT_SUCCESS });
          }
          dispatch({ type: RESET_MATCH_LOADED });
          dispatch({ type: SET_COMING_FROM_CREATE_FALSE });
        } else {
          // No need to update it in the db
          await dispatch(updateUserDemographics(userDemographics, requiresVerification));
        }

        setSaving(false);
        routerHistory.push({
          pathname:
            routerHistory.location.state.prevPath ===
            '/oauth/record-not-matching'
              ? '/oauth/finding'
              : routerHistory.location.state.prevPath,
          state: {
            prevPath: '/oauth/profile',
          },
        });
      } catch (err) {
        setSaving(false);
        setToast(errorMessages.editError);
      }
    }
  };

  return (
    <div className="screen-container profile-container style-overrides">
      <h4 className="layout-padding-xlarge-vertical">
        Make sure your information is up-to-date and matches your COVID-19
        vaccine.
      </h4>

      <TextInput
        label={'First name'}
        id={'firstName'}
        value={form.firstName}
        placeholder=""
        onChange={handleChange}
        error={errors.firstName}
        onBlur={validateInput}
      />

      <TextInput
        label={'Last name'}
        id={'lastName'}
        value={form.lastName}
        placeholder=""
        onChange={handleChange}
        error={errors.lastName}
        onBlur={validateInput}
      />

      <Gender
        gender={form.gender}
        setGender={setGender}
        error={errors.gender}
      />

      <TextInput
        type={'date'}
        id={'dob'}
        label={'Date of birth'}
        value={form.dob}
        placeholder={'mm/dd/yyyy'}
        onChange={(e) => handleDOB(e)}
        error={errors.dob}
      />

      <PhoneInput
        maxLength={14}
        id={'phoneNumber'}
        label={'Phone number'}
        onChange={handleChange}
        value={form.phoneNumber ? phoneFormatter(form.phoneNumber) : ''}
        phoneType={form.phoneType}
        onChangePhoneType={handleChangePhoneType}
        placeholder={'(111) 111-1111'}
        error={errors.phoneNumber}
        onBlur={validateInput}
      />

      <h4 className={'layout-padding-large-bottom'}>Address (Optional)</h4>

      <Address
        address={form.address}
        onChange={handleAddress}
        toggleItem={toggleDropdown}
        error={errors}
        onBlur={validateInput}
      />

      <p className={'toast-error'}>{toast ? toast : ''}</p>

      <div className={'layout-padding-large-top layout-padding-small-bottom'}>
        <Button
          id={'saveChanges'}
          className={
            'Button Button--primary default__button-label default__button-label--primary'
          }
          onClick={() => submitUserInfo(demographics)}
          disabled={saving}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Profile;
