import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SimplePropertyDisplay from '../../shared/components/SimplePropertyDisplay/SimplePropertyDisplay';
import Button from '../../shared/components/Button';
import { isNameValid, phoneFormatter } from '../../shared/utilities/regex';
import { setContainerHeight } from '../../shared/utilities/effects';
import { signupUser } from '../../../redux/actions/user';
import './ConfirmDataMatch.scss';

const ConfirmDataMatch = () => {
  const history = useHistory();
  const location = useLocation();
  const targetRef = useRef();
  const [screenHeight, setScreenHeight] = useState(0);
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { auth, demographics, password, email, cognitoId } = useSelector(
    (state) => state.user.user
  );
  const { error: cognitoError } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (targetRef.current) {
      setScreenHeight(
        setContainerHeight({
          useHeader: true,
          useFooter: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (demographics) {
      if (
        demographics.firstName &&
        demographics.lastName &&
        demographics.dob &&
        demographics.phoneNumber
      ) {
        // Check if the name has any invalid characters, if so redirect to the update screen
        const name = demographics.firstName + ' ' + demographics.lastName;
        if (!isNameValid(name)) {
          redirectToUpdatePage();
        } else {
          setFullName(name);
          setDateOfBirth(getFormattedDate(demographics.dob));
          setPhoneNumber(phoneFormatter(demographics.phoneNumber));
        }
      } else {
        redirectToUpdatePage();
      }
    } else {
      redirectToUpdatePage();
    }
  }, []);

  const getFormattedDate = (date) => {
    return moment(date).utc().format('MMM DD, YYYY');
  };

  useEffect(() => {
    if (cognitoError && cognitoError.message) {
      history.push({
        pathname: '/oauth/sign-in',
        state: { prevPath: history.location.pathname },
      });
    }
  }, [cognitoError]);

  useEffect(() => {
    if (cognitoId) {
      history.push({
        pathname: '/oauth/vaccine-confirmation-form',
        state: { prevPath: history.location.pathname },
      });
    }
  }, [cognitoId]);

  const createUser = (demographics, email, password) => {
    if (demographics && email && password) {
      dispatch(signupUser(demographics, email, password));
    }
  };

  const redirectToUpdatePage = () => {
    history.push({
      pathname: '/oauth/profile',
      state: { prevPath: '/oauth/confirm-data-match' },
    });
  };

  const handleContinueButton = (demographics, email, password) => {
    if (
      location.state &&
      location.state.prevPath &&
      (location.state.prevPath === '/oauth/finding' ||
        (auth &&
          auth.attributes &&
          auth.attributes.sub &&
          location.state.prevPath === '/oauth/profile'))
    ) {
      history.push('/oauth/vaccine-confirmation-form');
    } else {
      createUser(demographics, email, password);
    }
  };

  return (
    <div
      className={
        'screen-container confirm-data-match__flex-container style-overrides'
      }
      ref={targetRef}
      style={{ height: screenHeight + 'px' }}
    >
      <h4 className={'layout-padding-large-bottom layout-padding-xlarge-top'}>
        Does your personal information match your COVID-19 vaccine registration
        information?
      </h4>

      <div
        className={
          'flex-container flex-column flex-align-stretch flex-fullsize' +
          ' flex-nowrap flex-justify-space-between layout-padding-large-top'
        }
      >
        <div>
          <SimplePropertyDisplay label={'Name'} text={fullName} bold />
          <SimplePropertyDisplay
            label={'Date of birth'}
            text={dateOfBirth}
            bold
          />
          <SimplePropertyDisplay
            label={'Phone number'}
            text={phoneNumber}
            bold
          />
        </div>

        <div>
          <div className={'layout-padding-small-top'}>
            <Link
              to={{
                pathname: '/oauth/profile',
                state: { prevPath: history.location.pathname },
              }}
            >
              <Button
                id={'update'}
                className={
                  'Button default__button-label Button__border-primary layout-margin-small-top'
                }
              >
                Update my information
              </Button>
            </Link>
          </div>
          <div className={'layout-padding-small-top'}>
            <Button
              id={'continue'}
              className={
                'Button Button--primary default__button-label default__button-label--primary'
              }
              onClick={() =>
                handleContinueButton(demographics, email, password)
              }
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDataMatch;
